import React, { useState, useEffect } from "react";
import "./style.css";
import { Form, FormGroup, Input, Button, Row, Col } from "react-bootstrap";
// import Image from "next/image";
import background from "../assets/dashboard.jpg";
import logo from "../assets/logo.png";
// import Link from "next/link";
import { useMutation } from "react-query";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {Domain} from "../config/default";
// import Router from "next/router";
import "./style.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    console.log("credentials:", credentials);
    mutation.mutate(credentials);
  };

  // Mutations
  const mutation = useMutation((data) => {
    return axios.post(`${Domain}/admin/login`, data);
  });

  console.log("mutation:", mutation);

  useEffect(() => {
    if (mutation?.status === "success") {
      toast.success(mutation?.data?.data?.message);
      const accessToken = mutation?.data?.data?.admin?.accessToken;
      const refreshToken = mutation?.data?.data?.admin?.refreshToken;

      localStorage.setItem("access-token", accessToken);
      localStorage.setItem("refresh-token", refreshToken);
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      navigate("/");
      // Router.push("/");
    }
    if (mutation?.status === "error") {
      toast.error(mutation?.error?.response?.data?.message);
    }
  }, [mutation?.status]);

  return (
    <>
      <ToastContainer />
      <div>
        {/* {loading && <LinearProgress />} */}
        <Row className="m-0 p-0 vh-100">
          <Col
            xxl="8"
            className="login-image-section"
            style={{
              // backgroundImage: `linear-gradient(rgba(168 202 103/ 0.57),
              // rgba(205 205 205/ 0.56)), url(${background.src})`,
              backgroundImage: `linear-gradient(90deg, #a7c9683d, #ffffffa6, #a7c9683d), url(${background})`,
              objectFit: "fill",
              height: "100vh",
              backgroundSize: "cover",
              // opacity:"0.8"
              // backgroundSize: "100% 100vh, cover",
              // backgroundRepeat:"no-repeat"
            }}
          >
            <div className="relative vh-100 d-flex align-items-center justify-content-center">
              <img
                className="image-logo-login px-3 my-4"
                //   loader={myLoader}
                src={logo}
                alt="logo"
                fill
                // width={320}
                // height={50}
              />
            </div>
          </Col>

          <Col
            xxl="4"
            style={{ backgroundColor: "rgb(173 191 143 / 6%)" }}
            className="d-flex align-items-center mb-3"
          >
            <Form className="w-100 form-main" onSubmit={(e) => handleLogin(e)}>
              <h3 className="family-jost font-size-40 text-center my-5 font-weight-800 heading-color-343434ed">
                Admin Panel
              </h3>

              <input
                className="input-field my-4 py-3"
                type="email"
                placeholder="Enter email"
                value={credentials?.email}
                name="email"
                onChange={(e) => handleChange(e)}
                required
              />

              <input
                className="input-field my-4 py-3"
                type="password"
                placeholder="Password"
                value={credentials?.password}
                name="password"
                onChange={(e) => handleChange(e)}
                required
              />

              {/* <Link href="/signup" className="text-decoration-none"> */}
              <p
                className="font-size-14 family-jost pointer text-secondary text-end mx-2 my-4"
                onClick={() => navigate("/signup")}
              >
                Don't have an account?
              </p>
              {/* </Link> */}

              <button
                // onClick={(e) => e.preventDefault()}
                className="w-100 py-3 full-button text-light family-jost font-size-18"
                // variant="primary"
                type="submit"
              >
                LOGIN
              </button>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Login;
