import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link, Outlet, redirect } from "react-router-dom";
import "./style.css";
import Logo from "../assets/logo.jpg";
import logo from "../assets/logo.png";
import { FaBeer } from "react-icons/fa";
// import { BsPeople } from "react-icons/bs";
import { SlQuestion } from "react-icons/sl";
import { TfiGallery } from "react-icons/tfi";
import { RiAdminLine } from "react-icons/ri";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { TbRulerMeasure } from "react-icons/tb";
// import { RiLogoutCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { BsPeople } from "react-icons/bs";
import { FaDollyFlatbed } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import { RiContactsLine } from "react-icons/ri";
// import { MdOutlineNotificationsActive } from "react-icons/md";
import { RiLogoutCircleLine } from "react-icons/ri";
import { GiBackwardTime } from "react-icons/gi";
import axios from "axios";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";

const SidebarMain = () => {
  const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
    useProSidebar();

  const navigate = useNavigate();
  // const router = useRouter();
  const [list] = useState([
    "Dashboard",
    // "Users",
    "Products",
    "Categories",
    "Sizes",
    // "Notification",
    "Details",
    "Logout",
  ]);
  // console.log("list:", list);
  const [active, setActive] = useState(null);
  const handleListActive = (list) => {
    setActive(list);
    if (list === "Logout") {
      localStorage.removeItem("refresh-token");
      localStorage.removeItem("access-token");
      toast.success("User logged out!");
      setTimeout(() => {
        navigate("/login");
      }, 100);
    }
  };

  return (
    <div className="sidebar-layout">
      <>
        <ToastContainer />
        <Navbar className="navbar-main" expand="lg">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
              <Nav className="flex-column w-100" style={{ height: "100vh" }}>
                <span className="mx-auto my-4">
                  <Link to="/" className="w-100 relative">
                    <img
                      className="image px-3 my-4"
                      //   loader={myLoader}
                      src={logo}
                      alt="logo"
                      fill
                      // width={320}
                      // height={50}
                    />
                  </Link>
                </span>

                {list?.map((list, index) => {
                  return (
                    <Link
                      key={index}
                      to={
                        list === "Dashboard"
                          ? "/"
                          // : list === "Users"
                          // ? "/users"
                          : list === "Products"
                          ? "/products"
                          : list === "Categories"
                          ? "/categories"
                          : list === "Sizes"
                          ? "/sizes"
                          : list === "Details"
                          ? "/details"
                          // : list === "Notification"
                          // ? "/notification"
                          : ""
                      }
                      onClick={() => handleListActive(list)}
                      className={`nav-links ${active == list && "active"} mb-4`}
                    >
                      {index == 0 ? (
                        <MdOutlineDashboardCustomize className="mb-1 nav-link-icon" />
                      ) : index == 1 ? (
                        <BsPeople className="mb-1 nav-link-icon" />
                      ) : index == 2 ? (
                        <FaDollyFlatbed className="mb-1 nav-link-icon" />
                      ) : index == 3 ? (
                        <BiCategory className="mb-1 nav-link-icon" />
                      ) : index == 4 ? (
                        <TbRulerMeasure className="mb-1 nav-link-icon" />
                        ) : index == 5 ? (
                        <RiContactsLine className="mb-1 nav-link-icon" />
                      ) : index == 6 ? (
                        <RiLogoutCircleLine className="mb-1 nav-link-icon" />
                      ) : index == 7 ? (
                        <GiBackwardTime className="mb-1 nav-link-icon" />
                      ) : (
                        ""
                      )}

                      <span className="mx-3">{list}</span>
                    </Link>
                  );
                })}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* <Sidebar className="navbar-main">
          <Menu className="w-100" style={{ height: "100vh" }}>
            <MenuItem className="pt-2 pb-4">
              <Link to="/" className="w-100 relative">
                <img className="image" src={logo} alt="logo" fill />
              </Link>
            </MenuItem>
            {list?.map((list, index) => {
              return (
                <Link
                  key={index}
                  to={
                    list === "Dashboard"
                      ? "/"
                      : list === "Users"
                      ? "/users"
                      : list === "Products"
                      ? "/products"
                      : list === "Categories"
                      ? "/categories"
                      : list === "Notification"
                      ? "/notification"
                      : ""
                  }
                  onClick={() => handleListActive(list)}
                  className={`nav-links ${active == list && "active"} mb-4`}
                >
                  <MenuItem className="my-2">
                    {index == 0 ? (
                      <MdOutlineDashboardCustomize className="mb-1 nav-link-icon" />
                    ) : index == 1 ? (
                      <BsPeople className="mb-1 nav-link-icon" />
                    ) : index == 2 ? (
                      <FaDollyFlatbed className="mb-1 nav-link-icon" />
                    ) : index == 3 ? (
                      <BiCategory className="mb-1 nav-link-icon" />
                    ) : index == 4 ? (
                      <MdOutlineNotificationsActive className="mb-1 nav-link-icon" />
                    ) : index == 5 ? (
                      <RiLogoutCircleLine className="mb-1 nav-link-icon" />
                    ) : index == 6 ? (
                      <GiBackwardTime className="mb-1 nav-link-icon" />
                    ) : (
                      ""
                    )}

                    <span className="mx-3">{list}</span>
                  </MenuItem>
                </Link>
              );
            })}
          </Menu>
        </Sidebar> */}
      </>
      <Outlet />
    </div>
  );
};

export default SidebarMain;
