import React, { useEffect } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import Login from "./login";

const Protected = ({ isLoggedIn, children }) => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access-token");
  // console.log("accessToken:", accessToken);
  // console.log("isLoggedIn:", isLoggedIn);

  useEffect(() => {
    // if (isLoggedIn === null || isLoggedIn === undefined) {
      // console.log("accessToken", accessToken);
      // return redirect("/login");
      // return navigate("/login");
      if(!accessToken){
        return navigate("/login")
      }
    // }
  }, []);

  if (accessToken) {
    console.log("yes logged in:", isLoggedIn)
    return children;
  }
};

export default Protected;
