import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  NavDropdown,
  Dropdown,
  ButtonGroup,
  Table,
  Button,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import { ImBin } from "react-icons/im";
import { IoMdEye } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { BsPen } from "react-icons/bs";
// import Link from "next/link";
import { Link } from "react-router-dom";
import "./style.css";
// import Image from "next/image";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useQuery } from "react-query";
import axios from "axios";
import { Domain } from "../../config/default";
import { Spinner } from "react-bootstrap";
import { useMutation } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import Router, { useRouter } from "next/router";
import Subheader from "../../components/subheader";
import image from "../../assets/gram masala.jpg";
import { validate } from "../../components/auth";

const Users = () => {
  const navigate = useNavigate();
  // Get All Categories
  const getAllCategories = async () => {
    return await validate.get(`${Domain}/admin/category`);
  };

  const { isLoading, isError, isSuccess, data } = useQuery(
    "getAllCategories",
    getAllCategories
  );

  console.log("isError:", isError);
  console.log("isSuccess:", isSuccess);
  console.log("isLoading:", isLoading);
  console.log("data:", data);

  // pagination
  var [currentPage, setCurrentPage] = useState(1);
  const pageNumbers = [1, 2, 3, 4, 5];
  const [categories, setCategories] = useState();
  console.log("categories:", categories);

  useEffect(() => {
    console.log("state update called");
    setCategories(data?.data?.category);
  }, [data]);

  // Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const [deleteId, setDeleteId] = useState();

  const handleDelete = () => {
    // mutation.mutate(deleteId);
    handleClose();
  };

  //  Delete Mutations
  const mutation = useMutation(
    (id) => {
      return validate.delete(`${Domain}/admin/category/${id}`);
    }
    // {
    //   onSuccess: () => {
    //     // Invalidate and refetch
    //     queryClient.invalidateQueries("getAllCategories");
    //   },
    // }
  );

  //   const router = useRouter();
  console.log("mutation:", mutation);
  useEffect(() => {
    if (mutation?.status === "success") {
      toast.success("Successfully deleted");
      setTimeout(() => {
        // router.reload();
        window.location.reload();
      }, 500);
    }
    if (mutation?.status === "error") {
      toast.error("Could not delete category");
    }
  }, [mutation]);

  //  products
  const [users, setUsers] = useState([
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
    },
  ]);

  const handlePrev = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    console.log(pageNumbers?.length);
    console.log(currentPage);
    if (pageNumbers?.length > currentPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <ToastContainer />
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-5 rounded"
            xl="11"
            style={
              {
                // backgroundColor: "white",
                // // paddingTop: "5%",
                // // paddingLeft: "5%",
                // // paddingRight: "5%",
                // boxShadow: "1px 1px 5px 1px #BFBFBF",
              }
            }
          >
            <h5 className="text-start font-weight-500 mb-4">Users</h5>
            <Row
              className="bg-light rounded-3 mx-0 my-3 py-3"
              style={{ boxShadow: "0px 0px 5px 1px #B1b2b2" }}
            >
              <Col xxl="3" className="pt-2">
                <div className="search-bar">
                  <input className="search-field" placeholder="Search..." />
                  <AiOutlineSearch />
                </div>
              </Col>
            </Row>

            {isLoading && (
              <div className="d-flex">
                <Spinner animation="border" className="my-3 mx-auto" />
              </div>
            )}
            <Table
              className="w-100 bg-light my-4"
              responsive
              style={{
                boxShadow: "0px 0px 5px 1px #B1b2b2",
                borderRadius: "6px",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#e5e5e5" }}>
                  <th className="heading text-start px-3 py-3">Name</th>
                  <th className="heading text-start px-3 py-3">Email</th>
                  <th className="heading text-start px-3 py-3">Phone</th>
                  <th className="heading text-start px-3 py-3">City</th>
                  {/* <th className="heading text-start px-3 py-3 max-width-100">
                    Recommended
                  </th> */}

                  <th className="heading text-start px-3 py-3">Action</th>
                  <th className="heading text-start px-3 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {users?.length === 0 ? (
                  <p>No Users present in the Database</p>
                ) : (
                  users?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td className="text text-start px-3 pt-3 family-jost font-size-15 font-weight-600">
                          {user?.name}
                        </td>
                        <td className="text text-start px-3 py-3 family-jost font-size-15 d-flex max-width-200">
                          {user?.email}
                        </td>
                        <td className="text text-start px-3 py-3 family-jost font-size-15">
                          {user?.phone}
                        </td>
                        <td className="text text-start px-3 py-3 family-jost font-size-15">
                          {user?.city}
                        </td>
                        {/* <td className="text text-start px-3 py-3 font-weight-600 family-jost text-primary py-3 font-size-15 max-width-100">
                          {product?.recommended === true ? "✓" : "X"}{" "}
                        </td> */}
                        <td className="text text-start px-3 py-3">
                          {/* <Link href="/products/product-detail"> */}
                          <IoMdEye
                            color="blue"
                            size={16}
                            className=""
                            style={{ cursor: "pointer" }}
                            // onClick={() => handleSingleAdmission(form?._id)}
                            // onClick={() => navigate("/dashboard")}
                            onClick={() => navigate(`/detail-user/${2}`)}
                          />
                          {/* </Link> */}
                        </td>
                        <td className="text text-start px-3 py-3 ">
                          <ImBin
                            size={15}
                            color="red"
                            className=""
                            style={{ cursor: "pointer" }}
                            // onClick={() => handleDeleteForm(form?.id)}
                            onClick={() => handleShow(2)}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>

            <Row>
              <Col xxl="5" xl="6" lg="6" md="6" sm="8" className="mx-auto">
                <nav className="my-3">
                  <ul
                    className="pagination justify-content-center"
                    style={{ overflowX: "auto" }}
                  >
                    <li className="page-item text-dark">
                      <p
                        onClick={() => handlePrev()}
                        className="text px-3 py-1 rounded mx-3 pagination-button"
                      >
                        Prev
                      </p>
                    </li>
                    {pageNumbers?.map((pgNumber) => (
                      <li key={pgNumber}>
                        <p
                          className={`text page-item mx-1 px-3 py-1 ${
                            currentPage == pgNumber - 0 ? "activePage" : ""
                          } `}
                          style={{
                            cursor: "pointer",
                            boxShadow: "1px 1px 2px gray",
                          }}
                          onClick={() => setCurrentPage(pgNumber)}
                        >
                          {pgNumber}
                        </p>
                      </li>
                    ))}

                    <li className="page-item">
                      <p
                        onClick={() => handleNext()}
                        className="text px-3 py-1 rounded mx-3 pagination-button"
                      >
                        Next
                      </p>
                    </li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal className="family-jost" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Users;
