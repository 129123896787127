import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
// import Router, { useRouter } from "next/router";
import "./style.css";
import { useMutation } from "react-query";
import axios from "axios";
// import { Domain } from "@/components/config/lib";
import { Domain } from "../../config/default";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Subheader from "../../components/subheader";
import { IoCloseCircleOutline } from "react-icons/io5";
import { IoAddCircleOutline } from "react-icons/io5";
import { Multiselect } from "multiselect-react-dropdown";
import { validate } from "../../components/auth";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { AiOutlineCloudUpload } from "react-icons/ai";

const UpdateProduct = () => {
  const mutationRef = useRef();
  const [nameOfTags, setNameOfTags] = useState([]);
  const [listOfTags, setListOfTags] = useState([]);
  const [showResponse, setShowResponse] = useState(false)
  console.log("show response:", showResponse);
  // console.log("name of tags:", nameOfTags)
  // editor state
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  // console.log("editor state:", editorState);

  useEffect(() => {
    const currentContent = editorState.getCurrentContent();
    const newContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(newContent);
    // console.log("raw content:", rawContent);
    // const stringifyDescription = JSON.stringify(rawContent)
    const draftedDescription = draftToHtml(rawContent);
    // console.log("draftedDescription:", draftedDescription);
    setUpdateProduct({ ...updateProduct, description: draftedDescription });
  }, [editorState]);

  const onEditorChange = (data) => {
    setEditorState(data);
  };

  // get product details
  const navigate = useNavigate();
  const productFormData = new FormData();
  const { id } = useParams();
  //   const router = useRouter();
  //   console.log("router:", router);
  //   const id = router.query.slug;
  // console.log(("id:", id));

  // Get Single Product
  const getSingleProduct = async () => {
    return await validate.get(`${Domain}/admin/product`, {
      params: { id },
    });
  };

  const { isLoading, isError, isSuccess, data } = useQuery(
    "getSingleProduct",
    getSingleProduct
  );

  // console.log("isError:", isError);
  // console.log("isSuccess:", isSuccess);
  // console.log("isLoading:", isLoading);
  // console.log("data:", data);

  const [updateProduct, setUpdateProduct] = useState({
    name: "",
    description: "",
    price: "",
    availability: "",
    categoryId: "",
    prevImage: "",
    image: "",
    size: "",
    // length: "",
    // width: "",
    // height: "",
    tags: [],
  });

  console.log("update products:", updateProduct);

  // handle image in form data
  function handleImage(e) {
    // console.log("file type:", e.target.type);
    // console.log("updateProduct.image.length:", updateProduct?.image);

    // if (updateProduct.image.length > 0) {
    //   setUpdateProduct({
    //     ...updateProduct,
    //     image: [...updateProduct.image, e.target.files],
    //   });
    // } else {
    setUpdateProduct({
      ...updateProduct,
      image: e.target.files,
    });
    // }
  }

  // Get All Sizes
  const getAllSizes = async () => {
    return await validate.get(`${Domain}/admin/size`);
  };

  const {
    isLoading: isLoadingSize,
    isError: isErrorSize,
    isSuccess: isSuccessSize,
    data: dataSize,
  } = useQuery("getAllSizes", getAllSizes);

  // console.log("isErrorSize:", isErrorSize);
  // console.log("isSuccessSize:", isSuccessSize);
  // console.log("isLoadingSize:", isLoadingSize);
  // console.log("dataSize:", dataSize);

  const [subselect, setSubSelect] = useState();
  useEffect(() => {
    if (data) {
      // array of image urls
      const urls = [];
      data?.data?.product?.rows[0]?.productImages?.map((url, index) => {
        urls.push(url?.url);
      });

      setUpdateProduct({
        ...updateProduct,
        name: data?.data?.product?.rows[0]?.name,
        description: data?.data?.product?.rows[0]?.description,
        price: data?.data?.product?.rows[0]?.price,
        availability: data?.data?.product?.rows[0]?.availability,
        prevImage: urls,
        categoryId: data?.data?.product?.rows[0]?.categoryId,
        size: data?.data?.product?.rows[0]?.size,
        // length: data?.data?.product?.rows[0]?.length,
        // width: data?.data?.product?.rows[0]?.width,
        // height: data?.data?.product?.rows[0]?.height,
      });
      setListOfTags(data?.data?.product?.rows[0]?.tags);
      // data?.data?.product?.rows[0]?.tags?.map((tag, index) => {
      //   console.log("tagssss---:", tag?.name);
      //   if (listOfTags === []) {
      //     setListOfTags([tag?.name]);
      //   } else {
      //     setListOfTags([...listOfTags, tag?.name]);
      //   }
      // });
      setSizes(data?.data?.product?.rows[0]?.size);
      setSubSelect(dataSize?.data?.size?.rows);

      const blocksFromHTML = convertFromHTML(
        data?.data?.product?.rows[0]?.description
      );
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      setEditorState(EditorState.createWithContent(state));

      // const convertFromHtml = convertFromHTML(data?.data?.product?.rows[0]?.description)
      // console.log("convert from html:", convertFromHtml)
      // setEditorState(convertFromHtml);
    }
  }, [data]);

  useEffect(() => {
    let dummy = [];
    listOfTags?.map((list) => {
      dummy?.push(list?.name);
    });
    // console.log("dummy:", dummy)
    setNameOfTags(dummy);
  }, [listOfTags]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateProduct({
      ...updateProduct,
      [name]: value,
    });
  };

  const handleProduct = (e) => {
    console.log("e:", e.keyCode);
    console.log("update date:", updateProduct);
    e.preventDefault();
    if (e.keyCode == 13) {
      console.log("enter clicked");
    } else {
      productFormData.append("name", updateProduct?.name);
      if (updateProduct?.description == "") {
        toast.error("Description can not be empty")
        return;
      }
      if (updateProduct?.description == `<p></p>\n`) {
        toast.error("Description can not be empty")
        return;
      }
      else {
        productFormData.append("description", updateProduct?.description);
      }

      if (!updateProduct?.price || updateProduct?.price == "") {
        productFormData.append("price", 0);
      } else {
        productFormData.append("price", updateProduct?.price);
      }

      productFormData.append("categoryId", updateProduct?.categoryId);
      productFormData.append("availability", updateProduct?.availability);
      productFormData.append(
        "prevImage",
        JSON.stringify(updateProduct?.prevImage)
      );
      let sizesWithoutId = [...sizes];
      sizesWithoutId?.map((size, index) => {
        delete size?.id;
        delete size?.updatedAt;
        delete size?.createdAt;
      });
      productFormData.append("sizes", JSON.stringify(sizesWithoutId));

      var arrayTags = [];
      nameOfTags?.map((tag, index) => {
        arrayTags.push(tag);
      });

      if (arrayTags.length == 0) {
        toast.error("Tags could not be empty")
        return;
      } else {
        productFormData.append("tags", JSON.stringify(arrayTags));
      }

      if (sizes.length == 0) {
        toast.error("Sizes can not be empty")
        return;
      }

      if (updateProduct?.image) {
        for (let i = 0; i < updateProduct.image.length; i++) {
          productFormData.append("image", updateProduct.image[i]);
        }
      }
      // else {
      //   toast.error("Pictures can not be empty");
      //   return;
      // }

      e.preventDefault();
      mutation.mutate(productFormData);
    }
  };

  // Mutations
  // const mutation = useMutation((data) => {
  //   setShowResponse(true)
  //   return validate.put(`${Domain}/admin/product/${id}`, data);
  // });

  const mutation = useMutation(
    (data) => validate.put(`${Domain}/admin/product/${id}`, data),
    {
      onSuccess: () => {
        toast.success("Successfully updated");
        window.history.back();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "Could not update product");
      }
    }
  );
  console.log("mutation:", mutation);

  // Mutattion triggers mutationRef
  useEffect(() => {
    console.log("mutation useEfect called");
    mutationRef.current = mutation;
  }, [mutation]);

  console.log("mutation ref:", mutationRef);

  // find categories
  const getCategories = async () => {
    return await validate.get(`${Domain}/admin/category`);
  };

  const {
    isLoading: isLoadingCategory,
    isError: isErrorCategory,
    isSuccess: isSuccessCategory,
    data: dataCategory,
  } = useQuery("getCategories", getCategories);

  // console.log("isErrorCategory:", isErrorCategory);
  // console.log("isSuccessCategory:", isSuccessCategory);
  // console.log("isLoadingCategory:", isLoadingCategory);
  // console.log("dataCategory:", dataCategory);

  const [categories, setCategories] = useState();
  useEffect(() => {
    setCategories(dataCategory?.data?.category?.rows);
  }, [dataCategory]);
  // console.log("categories:", categories);

  var productCat;
  categories &&
    categories?.map((cat, index) => {
      if (cat?.id === data?.data?.product?.rows[0]?.categoryId) {
        productCat = cat?.name;
      }
    });

  // delete image
  const deleteImage = (id) => {
    // console.log("id", id);
    // console.log("product images:", updateProduct?.prevImage);
    var newPrevImages = [...updateProduct?.prevImage];
    // console.log("new previous images:", newPrevImages);
    newPrevImages.splice(id, 1);
    // console.log("new previous images 2:", newPrevImages);
    setUpdateProduct({
      ...updateProduct,
      prevImage: newPrevImages,
    });
  };

  // error code 401
  if (mutation?.error?.response?.status === 401) {
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("access-token");
    toast.success("User logged out!");
    setTimeout(() => {
      navigate("/login");
    }, 100);
  }

  // tags generator
  const [tagValue, setTagValue] = useState("");
  console.log("tag value:", tagValue);

  console.log("list of tags:", listOfTags);

  const generateTags = (e) => {
    console.log("key code:", e.keyCode);
    if (e.keyCode === 13 && tagValue) {
      // setListOfTags([...listOfTags, tagValue]);
      setNameOfTags([...nameOfTags, tagValue]);
      setTagValue("");
    }
  };

  const deleteATag = (id) => {
    // const dummy = [...listOfTags];
    const dummy = [...nameOfTags];
    dummy.splice(id, 1);
    // setListOfTags(dummy);
    setNameOfTags(dummy);
  };

  // sizes
  const [sizes, setSizes] = useState([
    {
      size: "",
      height: "",
      width: "",
      length: "",
      id: 1,
    },
  ]);
  console.log("sizes:", sizes);
  const handleIncreaseSize = () => {
    setSizes([
      ...sizes,
      {
        size: "",
        height: "",
        width: "",
        length: "",
        id: sizes[0]?.id + 1,
      },
    ]);
  };
  const handleDeleteSize = (id) => {
    if (sizes?.length === 1) {
      setShowPlus(false);
    }

    const dummy = [...sizes];
    dummy.splice(id, 1);
    setSizes(dummy);
  };

  const handleChangeSizes = (e, i) => {
    const { name, value } = e.target;
    // console.log("name:", name, "value:", value);
    const dummy = [...sizes];
    // console.log("dummy:", dummy[i][name]);
    dummy[i][name] = value;
    setSizes(dummy);
  };

  useEffect(() => {
    if (subselect) {
      const custom = {
        size: "Custom Dimentions",
      };
      setMultiselect([...dataSize?.data?.size?.rows]);
    }
  }, [subselect]);

  // multi select dropdowns
  const [multiselect, setMultiselect] = useState();
  // console.log("multiselect:", multiselect);

  // multiselect[multiselect?.length - 1]?.size === "Custom Dimentions" &&
  // console.log("truee------------------");

  const [showPlus, setShowPlus] = useState(false);
  const onSelect = (selectedList, selectedItem) => {
    // console.log("selected list:", selectedList);
    // console.log("selected item:", selectedItem);

    // if (selectedItem?.size === "Custom Dimentions") {
    //   setShowPlus(true);
    // }
    // if (selectedItem?.size !== "Custom Dimentions" && sizes?.length!==0) {
    if (sizes[0]?.size === "") {
      setSizes([selectedItem]);
    } else {
      setSizes([...sizes, selectedItem]);
    }
    // };
  };
  const onRemove = (remainingList, removedItem) => {
    // if (removedItem?.size === "Custom Dimentions" || sizes?.length === 0) {
    //   setShowPlus(false);
    // }
    let tobeDeleteid;
    const { id } = removedItem;
    sizes?.map((size, index) => {
      if (size?.id === id) {
        tobeDeleteid = index;
      }
    });
    // console.log("removed id:", id);
    const dummy = [...sizes];
    dummy.splice(tobeDeleteid, 1);
    setSizes(dummy);
  };

  return (
    <>
      <ToastContainer />
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-4 p-5 bg-white rounded"
            xl="11"
            style={{
              // backgroundColor: "white",
              boxShadow: "0px 1px 35px #e6e6e6",
            }}
          >
            <h5
              className="text-start font-weight-500 family-jost mb-2 pointer"
              onClick={() => window.history.back()}
            >
              {" "}
              <MdOutlineKeyboardBackspace fontSize={30} />{" "}
              <span className="mx-3"> Products </span>
            </h5>

            <Form
            //  onSubmit={handleProduct}
            >
              <Row className="mx-auto">
                <Col
                  xxl="8"
                  className="mx-auto p-4 pt-3"
                  style={{
                    boxShadow: "0px 1px 35px #e6e6e6",
                    borderRadius: "6px",
                  }}
                >
                  <Row className="my-2">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Product Name
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter product name"
                        name="name"
                        value={updateProduct?.name}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>

                    <Col xxl="6">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2">
                        Category
                      </p>
                      {/* <input
                        className="input-field"
                        placeholder="Enter product price"
                        name="categoryId"
                        value={addProduct?.categoryId}
                        onChange={(e) => handleChange(e)}
                      /> */}
                      <select
                        className="dropdown-field"
                        name="categoryId"
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option value={updateProduct?.categoryId}>
                          {productCat}
                        </option>
                        {/* <option value="none"> -- select category --</option> */}
                        {categories &&
                          categories?.map((category, index) => {
                            return (
                              <option value={category?.id} key={index}>
                                {category?.name}
                              </option>
                            );
                          })}
                      </select>
                    </Col>

                    {/* <Col xxl="6">
                      <p className="text-start font-size-18 family-jost font-weight-500 my-2">
                        Product Category
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter product category"
                      />
                    </Col> */}
                  </Row>

                  <Row className="my-3">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Price
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter product price"
                        name="price"
                        value={updateProduct?.price}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col xxl="6">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2">
                        Availability
                      </p>

                      <select
                        className="dropdown-field"
                        name="availability"
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option value={updateProduct?.availability}>
                          {updateProduct?.availability}
                        </option>
                        <option value="#"> -- select availability -- </option>
                        <option value="instock">In Stock</option>
                        <option value="sale">Sale</option>
                        <option value="outofstock">Out Of Stock</option>
                      </select>
                      {/* <input
                        className="input-field"
                        placeholder="Enter product price"
                        name="availability"
                        value={updateProduct?.availability}
                        onChange={(e) => handleChange(e)}
                      /> */}
                    </Col>
                  </Row>

                  {/* <Row className="my-3">
                    <Col xxl="6">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2">
                        Product Image
                      </p>
                      <input
                        type="file"
                        className="input-field border-0"
                        placeholder="Enter image"
                        name="image"
                        // value={addProduct?.categoryId}
                        onChange={(e) => handleImage(e)}
                        // onChange={(e) => handleChange(e)}
                        multiple
                      />
                    </Col>
                  </Row> */}

                  <Row className="mb-2">
                    <p className=" text-start font-size-18 font-weight-500 family-jost my-2">
                      Product Image
                    </p>
                    <Col lg="2">
                      <label className="custom-file-upload">
                        <input
                          type="file"
                          onChange={(e) => handleImage(e)}
                          multiple
                          required
                        />
                        <AiOutlineCloudUpload fontSize={30} color="#CF2D39" />
                        <p
                          style={{
                            color: "#CF2D39",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          upload{" "}
                        </p>
                      </label>
                    </Col>

                    {updateProduct?.image &&
                      Array.from(updateProduct?.image).map((file) => {
                        return (
                          <Col lg="2">
                            <img
                              src={URL.createObjectURL(file)}
                              style={{
                                width: "100%",
                                height: "89px",
                                objectFit: "contain",
                              }}
                              alt=""
                            />
                          </Col>
                        );
                      })}
                  </Row>

                  <p className="text-start font-size-18 font-weight-500 family-jost pt-2 m-0">
                    Sizes
                  </p>

                  <Row className="">
                    <Col xxl="6">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>

                      {/* <select
                        className="input-field"
                        name="availability"
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option value="#"> -- select size type -- </option>
                        <option value="custom-dimentions">
                          Custom dimentions
                        </option>
                        {allSizes?.map((size, index) => {
                          return (
                            <option value="custom-dimentions">
                              {size?.size}
                            </option>
                          );
                        })}
                      </select> */}

                      <Multiselect
                        className="input-field my-1"
                        options={multiselect} // Options to display in the dropdown
                        selectedValues={sizes}
                        Preselected
                        value
                        to
                        persist
                        in
                        dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="size" // Property name to display in the dropdown options
                      />
                    </Col>

                    <Col xxl="1">
                      {
                        // showPlus && (
                        // size?.size === "Custom Dimentions" &&
                        <IoAddCircleOutline
                          color="green"
                          className="mt-2 pointer"
                          fontSize={36}
                          onClick={handleIncreaseSize}
                        />
                        // )
                      }
                    </Col>
                  </Row>

                  {sizes?.map((size, index) => {
                    return (
                      <Row className="">
                        <Col xxl="3">
                          <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>
                          <input
                            className="input-field"
                            placeholder="Enter product size"
                            name="size"
                            value={size?.size}
                            onChange={(e) => handleChangeSizes(e, index)}
                            required
                          />
                        </Col>
                        <Col xxl="2">
                          <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>
                          <input
                            className="input-field"
                            placeholder="Length"
                            name="length"
                            value={size?.length}
                            onChange={(e) => handleChangeSizes(e, index)}
                            required
                          />
                        </Col>
                        <Col xxl="2">
                          <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>
                          <input
                            className="input-field"
                            placeholder="Width"
                            name="width"
                            value={size?.width}
                            onChange={(e) => handleChangeSizes(e, index)}
                            required
                          />
                        </Col>
                        <Col xxl="2">
                          <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>
                          <input
                            className="input-field"
                            placeholder="Height"
                            name="height"
                            value={size?.height}
                            onChange={(e) => handleChangeSizes(e, index)}
                            required
                          />
                        </Col>

                        <Col xxl="1">
                          {/* {index === 0 &&
                            size?.size === "Custom Dimentions" && (
                              <IoAddCircleOutline
                                color="green"
                                className="mt-2 pointer"
                                fontSize={36}
                                onClick={handleIncreaseSize}
                              />
                            )} */}
                          {
                            // index >= 0 && (
                            <IoCloseCircleOutline
                              color="red"
                              className="mt-2 pointer"
                              fontSize={36}
                              onClick={(e) => handleDeleteSize(index)}
                            />
                            // )
                          }
                        </Col>
                      </Row>
                    );
                  })}
                  {/* <Row className="my-3">
                    <p className=" text-start font-size-18 font-weight-500 family-jost my-2">
                      Sizes
                    </p>
                    <Col xxl="3">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>
                      <input
                        className="input-field"
                        placeholder="Enter product size"
                        name="size"
                        value={updateProduct?.size}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </Col>
                    <Col xxl="3">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>
                      <input
                        className="input-field"
                        placeholder="Length"
                        name="length"
                        value={updateProduct?.length}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </Col>
                    <Col xxl="3">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>
                      <input
                        className="input-field"
                        placeholder="Width"
                        name="width"
                        value={updateProduct?.width}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </Col>
                    <Col xxl="3">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>
                      <input
                        className="input-field"
                        placeholder="Height"
                        name="height"
                        value={updateProduct?.height}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </Col>
                  </Row> */}

                  <Row className="mt-3">
                    <Col xxl="12">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Tags
                      </p>
                      <div
                        className="d-flex"
                      // style={{ border: "1px solid gray" }}
                      >
                        {nameOfTags?.length > 0 &&
                          nameOfTags?.map((tag, index) => {
                            return (
                              <p
                                className="mx-1 px-2 d-flex py-1 rounded"
                                style={{ border: "1px solid gray" }}
                              >
                                {tag}{" "}
                                <span
                                  className="pointer"
                                  onClick={() => deleteATag(index)}
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <IoCloseCircleOutline fontSize={20} />
                                </span>
                              </p>
                            );
                          })}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xxl="12">
                      <input
                        className="input-field"
                        placeholder="Generate Tags"
                        name="generateTags"
                        value={tagValue}
                        onChange={(e) => setTagValue(e.target.value)}
                        onKeyDown={generateTags}
                      />
                      {/* <Select
                        closeMenuOnSelect={false}
                        // defaultValue={[colourOptions[0], colourOptions[1]]}
                        isMulti
                        options={options}
                        onChange={handleChangetag}
                        required
                        // styles={colourStyles}
                      /> */}
                    </Col>
                  </Row>

                  {/* <Row className="my-3">
                    <Col xxl="3">
                      <div className="d-flex">
                        <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                          Recommended
                        </p>
                        <input type="checkbox" className="mx-3" />
                      </div>
                    </Col>
                    <Col xxl="3">
                      <div className="d-flex">
                        <p className=" text-startfont-size-18 font-weight-500 family-jost my-2">
                          In Stock
                        </p>
                        <input type="checkbox" className="mx-3" />
                      </div>
                    </Col>
                  </Row> */}

                  {/* <Row className="my-3">
                    <Col xxl="12">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2">
                        Category Id
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter product price"
                        name="categoryId"
                        value={updateProduct?.categoryId}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                  </Row> */}

                  {/* <Row className="my-3">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Image
                      </p>
                      <input
                        className="input-field"
                        type="file"
                        placeholder="Enter product price"
                      />
                    </Col>
                  </Row> */}

                  <Row className="my-2">
                    <Col xxl="12">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Description
                      </p>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorChange}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        required
                      // wrapperClassName="input-field"
                      // editorClassName="editorClassName"
                      // onEditorStateChange={this.onEditorStateChange}
                      />
                      {/* <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Enter description of the product"
                          name="description"
                          value={updateProduct?.description}
                          onChange={(e) => handleChange(e)}
                          rows={4}
                        />
                      </Form.Group> */}
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                      Product Images
                    </p>
                    {updateProduct?.prevImage?.length > 0 &&
                      updateProduct?.prevImage?.map((image, index) => {
                        return (
                          <Col xxl="3" lg="4" md="6">
                            <img
                              src={image}
                              style={{ height: "100px", objectFit: "contain" }}
                              className="w-100"
                            />
                            <Button
                              variant="primary"
                              // type="submit"
                              // className="users-addbutton"
                              className="full-button bg-danger w-75 my-2"
                              onClick={() => deleteImage(index)}
                            // onClick={() => navigate("/add-product")}
                            >
                              Delete
                            </Button>
                          </Col>
                        );
                      })}
                  </Row>

                  <Row className="my-4">
                    <Col xxl="6" className="">
                      {
                        /* <Button className="w-100" variant="success" type="submit">
                      Submit
                    </Button> */
                        <Button
                          variant="primary"
                          // type="submit"
                          // className="users-addbutton"
                          className="full-button w-100"
                          // onKeyDown={handleProduct}
                          onClick={handleProduct}
                          onKeyDown={(e) => e.preventDefault()}
                        // onClick={() => navigate("/add-product")}
                        >
                          Update Product
                        </Button>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>

            {/* <Form onSubmit={(e) => handleProduct(e)}>
              <Row className="mx-auto">
                <Col xxl="7" className="mx-auto">
                  <Row className="my-2">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Product Name
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter product name"
                      />
                    </Col>

                    <Col xxl="6">
                      <p className="text-start font-size-18 family-jost font-weight-500 my-2">
                        Product Category
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter product category"
                      />
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Price
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter product price"
                      />
                    </Col>

                    <Col xxl="6">
                      <p className="text-start font-size-18 family-jost font-weight-500 my-2">
                        Sale Price
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter product sale price"
                      />
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col xxl="3">
                      <div className="d-flex">
                        <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                          Recommended
                        </p>
                        <input type="checkbox" className="mx-3" />
                      </div>
                    </Col>
                    <Col xxl="3">
                      <div className="d-flex">
                        <p className=" text-startfont-size-18 font-weight-500 family-jost my-2">
                          In Stock
                        </p>
                        <input type="checkbox" className="mx-3" />
                      </div>
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col xxl="6">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2">
                        Max Order Limit
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter product price"
                      />
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Image
                      </p>
                      <input
                        className="input-field"
                        type="file"
                        placeholder="Enter product price"
                      />
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col xxl="12">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Description
                      </p>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Enter description of the product"
                          rows={4}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col xxl="6" className="">
                      <Button
                        variant="primary"
                        type="submit"
                        className="full-button w-100"
                        onClick={() => navigate("/add-product")}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateProduct;
