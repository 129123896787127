import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
// import Router, { useRouter } from "next/router";
import "./style.css";
import { useMutation } from "react-query";
import axios from "axios";
// import { Domain } from "@/components/config/lib";
import { Domain } from "../../config/default";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Subheader from "../../components/subheader";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { validate } from "../../components/auth";
import { AiOutlineCloudUpload } from "react-icons/ai";

const UpdateCategory = () => {
  // editor state
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  // console.log("editor state:", editorState);

  useEffect(() => {
    const currentContent = editorState.getCurrentContent();
    const newContent = editorState.getCurrentContent();
    // console.log("new content:", newContent);
    const rawContent = convertToRaw(newContent);
    // console.log("raw content:", rawContent);

    const draftedDescription = draftToHtml(rawContent);
    // console.log("draftedDescription:", draftedDescription);
    setUpdateCategory({
      ...updateCategory,
      description: draftedDescription,
    });
  }, [editorState]);

  const onEditorChange = (data) => {
    setEditorState(data);
  };

  // get category details
  const categoryFormData = new FormData();
  const navigate = useNavigate();
  const { id } = useParams();
  //   const router = useRouter();
  //   console.log("router:", router);
  //   const id = router.query.slug;
  //   console.log(("id:", id));

  // Get Single Category
  const getSingleCategy = async () => {
    return await validate.get(`${Domain}/admin/category`, {
      params: { id },
    });
  };

  const { isLoading, isError, isSuccess, data } = useQuery(
    "getSingleCategy",
    getSingleCategy
  );

  // console.log("isError:", isError);
  // console.log("isSuccess:", isSuccess);
  // console.log("isLoading:", isLoading);
  // console.log("data:", data);

  const [updateCategory, setUpdateCategory] = useState();
  console.log("update category:", updateCategory);

  useEffect(() => {
    setUpdateCategory({
      ...updateCategory,
      image: data?.data?.category?.rows[0]?.image,
      name: data?.data?.category?.rows[0]?.name,
      description: data?.data?.category?.rows[0]?.description,
    });

    const desc = data?.data?.category?.rows[0]?.description;
    if (desc) {
      const blocksFromHTML = convertFromHTML(desc);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      setEditorState(EditorState.createWithContent(state));
    }
  }, [data]);
  // console.log("update category:", updateCategory);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateCategory({
      ...updateCategory,
      [name]: value,
    });
  };

  const handleCategory = (e) => {
    e.preventDefault();
    console.log("update category:", updateCategory);

    categoryFormData.append("name", updateCategory?.name);
    categoryFormData.append("description", updateCategory?.description);
    console.log("image-length", updateCategory.image?.length);
    console.log("image-object", updateCategory.image);
    if (updateCategory?.image?.length === 1) {
      for (let i = 0; i < updateCategory.image.length; i++) {
        categoryFormData.append("image", updateCategory.image[i]);
      }
    }
    // updateCategory?.mainCategoryId &&
    // categoryFormData.append("mainCategoryId", updateCategory?.mainCategoryId);
    // categoryFormData.get("image") && categoryFormData.append("mainCategoryId", addCategory?.mainCategoryId);

    mutation.mutate(categoryFormData);
  };

  // Get All Categories
  const getAllCategories = async () => {
    return await validate.get(`${Domain}/admin/category`);
  };

  const {
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    isSuccess: isSuccessCategories,
    data: dataCategories,
  } = useQuery("getAllCategories", getAllCategories);

  console.log("isErrorCategories:", isErrorCategories);
  console.log("isSuccessCategories:", isSuccessCategories);
  console.log("isLoadingCategories:", isLoadingCategories);
  console.log("dataCategories:", dataCategories);

  const [categories, setCategories] = useState();
  useEffect(() => {
    setCategories(dataCategories?.data?.category?.rows);
  }, [dataCategories]);
  console.log("categories:", categories);

  // handle image in form data
  const [iconPreview, setIconPreview] = useState();
  console.log("icon preview:", iconPreview);
  function handleImage(e) {
    console.log("e.target.files[0]", e.target.files);
    // console.log("updateProduct.image.length:", updateProduct?.image);

    // if (updateCategory.image.length > 0) {
    //   setUpdateCategory({
    //     ...updateCategory,
    //     image: [...updateCategory.image, e.target.files],
    //   });
    // } else {
    setUpdateCategory({
      ...updateCategory,
      image: e.target.files,
    });

    setIconPreview(URL.createObjectURL(e.target.files[0]));
    // }
  }

  // main category name
  var mainCategoryName;
  categories &&
    categories?.map((cat, index) => {
      if (cat?.id === data?.data?.category?.rows[0]?.id) {
        mainCategoryName = cat?.name;
      }
    });
  console.log("mainCategoryName:", mainCategoryName);

  // Mutations
  const mutation = useMutation((data) => {
    return validate.put(`${Domain}/admin/category/${id}`, data);
  });

  console.log("mutation:", mutation);

  useEffect(() => {
    if (mutation?.status === "success") {
      toast.success("Successfully updated");
      //   Router.back();
      window.history.back();
      // setInterval(() => {
      // }, 500);
    }
    if (mutation?.status === "error") {
      toast.error(mutation?.error?.response?.data?.message);
    }
  }, [mutation]);

  // error code 401
  if (mutation?.error?.response?.status === 401) {
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("access-token");
    toast.success("User logged out!");
    setTimeout(() => {
      navigate("/login");
    }, 100);
  }

  return (
    <>
      <ToastContainer />
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-5 p-5 rounded"
            xl="11"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 1px 35px #e6e6e6",
            }}
          >
            <h5
              className="text-start font-weight-500 family-jost mb-4 pointer"
              onClick={() => window.history.back()}
            >
              {" "}
              <MdOutlineKeyboardBackspace fontSize={30} />{" "}
              <span className="mx-3"> Categories </span>
            </h5>

            <Form onSubmit={(e) => handleCategory(e)}>
              <Row className="mx-auto my-0">
                <Col xxl="3" className="mt-0 mx-auto">
                  {/* <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                      Category Icon
                    </p> */}
                  <img
                    src={!iconPreview ? updateCategory?.image : iconPreview}
                    alt="img"
                    style={{ height: "250px", objectFit: "contain" }}
                    className="w-100"
                  />
                  {/* <input
                    name="category-icon"
                    onChange={(e) => handleImage(e)}
                   
                    type="file"
                    className="input-field mt-2"
                  /> */}

                  <label className="custom-file-upload-update my-3">
                    <input type="file" onChange={(e) => handleImage(e)} />
                    <AiOutlineCloudUpload fontSize={30} color="#CF2D39" />
                    <p
                      style={{
                        color: "#CF2D39",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      upload{" "}
                    </p>
                  </label>
                </Col>

                <Col xxl="7" className="mx-auto">
                  <Row className="mb-3">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost mb-2">
                        Category Name
                      </p>
                      <input
                        name="name"
                        onChange={(e) => handleChange(e)}
                        value={updateCategory?.name}
                        type="text"
                        className="input-field"
                        placeholder="Enter category name"
                        required
                      />
                    </Col>
                    {/* <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost mb-2">
                        Main Category
                      </p>
                      <select
                        className="input-field"
                        name="mainCategoryId"
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option value={updateCategory?.mainCategoryId}>
                          {mainCategoryName}
                        </option>
                        <option value="none"> -- select category --</option>
                        {categories &&
                          categories?.map((category, index) => {
                            return (
                              <option value={category?.id} key={index}>
                                {category?.name}
                              </option>
                            );
                          })}
                      </select>
                    </Col> */}
                  </Row>

                  <Row className="my-3">
                    <Col xxl="12">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Description
                      </p>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorChange}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        required
                        // wrapperClassName="input-field"
                        // editorClassName="editorClassName"
                        // onEditorStateChange={this.onEditorStateChange}
                      />
                      {/* <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          name="description"
                          onChange={(e) => handleChange(e)}
                          value={updateCategory?.description}
                          type="text"
                          placeholder="Enter description of the category"
                          rows={4}
                          required
                        />
                      </Form.Group> */}
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col xxl="6" className="">
                      <Button
                        className="w-100 full-button"
                        variant="success"
                        type="submit"
                        // onClick={() => mutation.mutate(addCategory)}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateCategory;
