import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import apple from "../../assets/apple.jpg";
import banana from "../../assets/banana.jpg";
// import Image from "next/image";
import "./style.css";
// import Router from "next/router";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router-dom";
import Subheader from "../../components/subheader";
import { useQuery } from "react-query";
import axios from "axios";
import { Domain } from "../../config/default";
import {
  convertFromRaw,
  convertFromHTML,
  Editor,
  EditorState,
  ContentState,
} from "draft-js";
import { validate } from "../../components/auth";
import draftToHtml from "draftjs-to-html";

const DetailProduct = () => {
  // react-slack
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };
  const [isReadMore, setIsReadMore] = useState(true);
  const navigate = useNavigate();
  // Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    // setDeleteId(id);
    setShow(true);
  };

  // editor state
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  // console.log("editor state:", editorState);

  // Find single product by id
  const { id } = useParams();
  // console.log("id", id);

  const getSingleProduct = async () => {
    return await validate.get(`${Domain}/admin/product`, {
      params: { id },
    });
  };

  const { isLoading, isError, isSuccess, data } = useQuery(
    "getSingleProduct",
    getSingleProduct
  );

  // console.log("isError:", isError);
  // console.log("isSuccess:", isSuccess);
  // console.log("isLoading:", isLoading);
  // console.log("data:", data);

  const [product, setProduct] = useState();
  console.log("product:", product);
  useEffect(() => {
    setProduct(data?.data?.product?.rows[0]);
  }, [data]);

  useEffect(() => {
    // console.log("product description:", JSON.stringify(product?.description));
    let convertedState, editorValue;
    // if (product?.description) {
    //   convertedState = convertFromHTML(product?.description);
    //   console.log("convertedState:", convertedState);
    //   editorValue = EditorState.createWithContent(convertedState?.getCurrentContent());
    // }
  }, [product]);

  // find categories
  const getCategories = async () => {
    return await validate.get(`${Domain}/admin/category`);
  };

  const {
    isLoading: isLoadingCategory,
    isError: isErrorCategory,
    isSuccess: isSuccessCategory,
    data: dataCategory,
  } = useQuery("getCategories", getCategories);

  // console.log("isErrorCategory:", isErrorCategory);
  // console.log("isSuccessCategory:", isSuccessCategory);
  // console.log("isLoadingCategory:", isLoadingCategory);
  // console.log("dataCategory:", dataCategory);

  const [categories, setCategories] = useState();
  useEffect(() => {
    setCategories(dataCategory?.data?.category?.rows);
  }, [dataCategory]);
  // console.log("categories:", categories);

  var productCat;
  categories &&
    categories?.map((cat, index) => {
      if (cat?.id === product?.categoryId) {
        productCat = cat?.name;
      }
    });

  return (
    <>
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-5 rounded"
            xl="11"
          >
            <Row
              className="mx-2 px-3"
              style={{
                backgroundColor: "white",
                boxShadow: "0px 1px 35px #e6e6e6",
              }}
            >
              <h5
                className="text-start font-weight-500 family-jost my-4 pointer"
                onClick={() => window.history.back()}
              >
                {" "}
                <MdOutlineKeyboardBackspace fontSize={30} />{" "}
                <span className="mx-3"> Product </span>
              </h5>
              <Col
                xxl="5"
                className="p-4 pt-5"
              >
                <Carousel>
                  {product?.productImages?.map((images, index) => {
                    return (
                      <img
                        className="image"
                        style={
                          {
                            objectFit: "contain"
                          }
                        }
                        src={images?.url}
                        alt="logo"
                        fill
                      />
                    );
                  })}
                </Carousel>
              </Col>

              <Col
                xxl="5"
                className="p-4 mx-4"
                style={
                  {
                    // backgroundColor: "white",
                    // boxShadow: "0px 1px 35px #e6e6e6",
                  }
                }
              >
                <h3 className="text-start font-weight-500 family-jost font-size-50">
                  {product?.name}
                </h3>
                <p className="text-start font-size-18 m-0 my-4 font-weight-800 family-jost">
                  {" "}
                  Description:
                </p>
                {isReadMore ? (
                  <span className="">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product?.description?.slice(0, 100),
                      }}
                    />
                    {
                      product?.description?.length > 100 && <p
                        style={{ cursor: "pointer" }}
                        className="text-primary text-start"
                        onClick={() => setIsReadMore(!isReadMore)}
                      >
                        {" "}
                        read more{" "}
                      </p>
                    }

                  </span>
                ) : (
                  <span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product?.description,
                      }}
                    />
                    <p
                      style={{ cursor: "pointer" }}
                      className="text-primary text-start"
                      onClick={() => setIsReadMore(!isReadMore)}
                    >
                      {" "}
                      read less{" "}
                    </p>
                  </span>
                )}
                <p className="text-start font-size-18 m-0 mb-4 mt-2 font-weight-400 family-jost">
                  {" "}
                  <span className="font-weight-800"> Price: </span>{" "}
                  {product?.price}
                </p>
                <p className="text-start font-size-18 m-0 my-4 font-weight-400 family-jost">
                  {" "}
                  <span className="font-weight-800"> Category: </span>{" "}
                  {productCat}
                </p>
                <p className="text-start font-size-18 m-0 my-4 font-weight-400 family-jost">
                  {" "}
                  <span className="font-weight-800"> Availability: </span>{" "}
                  <span
                    className={
                      product?.availability === "sale"
                        ? "bg-success text-light px-4 py-1 rounded-pill family-jost font-size-15 mx-3"
                        : "bg-primary text-light px-4 py-1 rounded-pill family-jost font-size-15 mx-3"
                    }
                  >
                    {product?.availability}
                  </span>
                </p>
                <p
                  className="text-start font-size-18 m-0 my-3 font-weight-400 family-jost"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {" "}
                  <span className="font-weight-800 my-auto"> Tags: </span>{" "}
                  {product?.tags?.map((tag, index) => {
                    return (
                      <span
                        className="text-black px-4 py-1 rounded-pill family-jost font-size-15 mx-3 my-1"
                        style={{ border: "1px solid black", maxWidth: "100%" }}
                      >
                        {tag?.name}
                      </span>
                    );
                  })}
                </p>
                <Row className="mt-2 mb-2">
                  <Col xxl="1" lg="1">
                    <span className="text-start font-size-18 m-0 font-weight-800 family-jost">
                      {" "}
                      Sizes:{" "}
                    </span>{" "}
                  </Col>
                  <Col xxl="9" lg="9" className="mx-3 text-secondary">
                    <p className="text-start font-size-18 m-0 font-weight-400 family-jost">
                      {" "}
                      {product?.size?.map((dimentions, index) => {
                        return (
                          <p className="m-0 p-0">
                            <span className=""> {dimentions?.size} </span>
                            <span className="mx-2">
                              {dimentions?.length}(L)
                            </span>
                            <AiOutlineClose className="" />
                            <span className="mx-2">{dimentions?.width}(W)</span>
                            <AiOutlineClose className="" />
                            <span className="mx-2">
                              {dimentions?.height}(H)
                            </span>
                          </p>
                        );
                      })}
                    </p>
                  </Col>
                </Row>
                {/* <p className="text-start mt-3 text-start font-size-18 m-0 font-weight-800 family-jost">
                  Description
                </p>
                <ul className="text-start mb-3 mx-0 family-jost">
                  <li className="font-size-18 ">Packed with nutrients</li>
                  <li className="font-size-18 ">Packed with nutrients</li>
                  <li className="font-size-18 ">Packed with nutrients</li>
                  <li className="font-size-18 ">Packed with nutrients</li>
                  <li className="font-size-18 ">Packed with nutrients</li>
                </ul> */}
                <div className="d-flex">
                  <Button
                    className="w-50 my-3 full-button"
                    variant="primary"
                    type="submit"
                    // onClick={() => handleShow(2)}
                    onClick={() => navigate(`/update-product/${product?.id}`)}
                  >
                    Edit
                  </Button>
                </div>
              </Col>
            </Row>

            {/* <Carousel className="w-100" 
            // width={650}
             showArrows={true} showThumbs={false} thumbWidth={500}>
                  <div className="relative">
                    <Image
                      className="image"
                      style={{
                        paddingLeft: "10%",
                        paddingRight: "10%",
                        paddingBottom: "10%",
                      }}
                      src="/images/brocli.jfif"
                      alt="logo"
                      fill
                    />
                  </div>

                  <div className="relative">
                    <Image
                      className="image"
                      style={{
                        paddingLeft: "10%",
                        paddingRight: "10%",
                        paddingBottom: "10%",
                      }}
                      src="/images/apple.jpg"
                      alt="logo"
                      fill
                    />
                  </div>
                </Carousel> */}
          </Col>
        </Row>
      </Container>

      <Modal className="family-jost" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this category?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger">Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DetailProduct;
