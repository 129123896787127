import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Subheader from "../../components/subheader";
import { AiOutlineNotification } from "react-icons/ai";
import { FcAbout } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Editor } from "react-draft-wysiwyg";
import "./style.css";
import { toolbar } from "./toolbar";
import { useMutation } from "react-query";
import axios from "axios";
import { validate } from "../../components/auth";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState } from "draft-js";

const AddDetails = () => {
  const [details, setDetails] = useState({
    officeAddress: "",
    phone: "",
    email: "",
    date: "",
    offDays: "",
    storeAddress: "",
    storeHours: "",
    lat: "20",
    long: "89",
    description: "",
  });

  // editor state
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  console.log("editor state:", editorState);

  useEffect(() => {
    // const currentContent = editorState.getCurrentContent();
    const newContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(newContent);
    console.log("raw content:", rawContent);
    // const stringifyDescription = JSON.stringify(rawContent)
    const draftedDescription = draftToHtml(rawContent);
    console.log("draftedDescription:", draftedDescription);
    setDetails({ ...details, description: draftedDescription });
  }, [editorState]);

  const onEditorChange = (data) => {
    setEditorState(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("details", details);
    mutation.mutate(details);
  };

  const navigate = useNavigate();

  // Mutations
  const mutation = useMutation((data) => {
    return validate.post(`/contact`, data);
  });

  console.log("mutation:", mutation);

  // error code 401
  if (mutation?.error?.response?.status === 401) {
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("access-token");
    // toast.success("User logged out!");
    setTimeout(() => {
      navigate("/login");
    }, 100);
  }

  useEffect(() => {
    if (mutation?.status === "success") {
      toast.success(mutation?.error?.response?.data?.message);
      // toast.success(data?.data?.message);
      navigate("/details");
    }
    if (mutation?.status === "error") {
      toast.error(mutation?.error?.response?.data?.message);
    }
  }, [mutation]);

  return (
    <>
      <ToastContainer />
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto bg-light mt-4 p-5 rounded"
            xl="11"
            style={{
              // backgroundColor: "white",
              boxShadow: "0px 1px 35px #e6e6e6",
            }}
          >
            <h5
              className="text-start font-weight-500 family-jost mb-4 pointer"
              onClick={() => window.history.back()}
            >
              {" "}
              <MdOutlineKeyboardBackspace fontSize={30} />{" "}
              <span className="mx-3"> Details </span>
            </h5>

            <Form onSubmit={(e) => handleSubmit(e)}>
              <Row className="">
                <Col className="mx-4">
                  <Row className="my-2">
                    <Col xxl="12">
                      <span className="font-weight-500 font-size-18 text-secondary family-jost mb-2">
                        {" "}
                        The Office{" "}
                      </span>
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Address
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter office address"
                        name="officeAddress"
                        value={details?.officeAddress}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Phone Number
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter phone"
                        name="phone"
                        value={details?.phone}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col xxl="6">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2">
                        Email
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter email"
                        name="email"
                        value={details?.email}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                  </Row>

                  <Row className="my-2">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Date & Time
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter date and time"
                        name="date"
                        value={details?.date}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Off Days
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter offdays"
                        name="offDays"
                        value={details?.offDays}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-5">
                    <Col xxl="6" className="">
                      {
                        /* <Button className="w-100" variant="success" type="submit">
                      Submit
                    </Button> */
                        <Button
                          variant="primary"
                          type="submit"
                          // className="users-addbutton"
                          className="full-button w-100"
                          //   onClick={() => navigate("/add-product")}
                        >
                          Add Detail
                        </Button>
                      }
                    </Col>
                  </Row>
                </Col>
                <Col className="mx-4">
                  <span className="font-weight-500 font-size-18 text-secondary family-jost">
                    {" "}
                    The Store{" "}
                  </span>

                  <Row className="my-2">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Address
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter store address"
                        name="storeAddress"
                        value={details?.storeAddress}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Hours
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter store hours"
                        name="storeHours"
                        value={details?.storeHours}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col xxl="12">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        About us text
                      </p>
                      {/* <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Enter about us details here ..."
                          rows={4}
                        />
                      </Form.Group> */}
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorChange}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="input-field"
                        editorClassName="editorClassName"
                        // onEditorStateChange={this.onEditorStateChange}
                        // onEditorStateChange={onEditorChange}
                        // toolbar={toolbar}
                        // toolbar={{
                        //   // inline: { inDropdown: true },
                        //   list: { inDropdown: true },
                        //   textAlign: { inDropdown: true },
                        //   link: { inDropdown: true },
                        //   history: { inDropdown: true },
                        //   fontSize: {
                        //     // icon: fontSize,
                        //     options: [
                        //       8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60,
                        //       72, 96,
                        //     ],
                        //     className: undefined,
                        //     component: undefined,
                        //     dropdownClassName: undefined,
                        //   },
                        //   fontFamily: {
                        //     options: [
                        //       "Arial",
                        //       "Georgia",
                        //       "Impact",
                        //       "Tahoma",
                        //       "Times New Roman",
                        //       "Verdana",
                        //     ],
                        //     className: undefined,
                        //     component: undefined,
                        //     dropdownClassName: undefined,
                        //   },
                        // }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddDetails;
