import logo from "./logo.svg";
import "./App.css";
// import Faqs from "./components/faqs";
import Dashboard from "./pages/Dashboard";
// import Students from "./components/students";
import Sidebar from "./components/sidebar";
// import Gallery from "./components/gallery";
// import Admin from "./components/admin";
// import NoticeWindow from "./components/noticeWindow";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Login from "./components/login";
import Signup from "./components/signup";
// import Admissions from "./components/admissions";
// import AdmissionsDetail from "./components/admissionsDetail";
// import Courses from "./components/courses";
import Categories from "./pages/categories/Categories";
import DetailCategory from "./pages/categories/DetailCategory";
import AddCategory from "./pages/categories/AddCategory";
import UpdateCategory from "./pages/categories/UpdateCategory";
import Products from "./pages/products/Products";
import AddProduct from "./pages/products/AddProduct";
import DetailProduct from "./pages/products/DetailProduct";
import UpdateProduct from "./pages/products/UpdateProduct";
import Users from "./pages/users/Users";
import DetailUser from "./pages/users/DetailUser";
import Notification from "./pages/Notification";
import Details from "./pages/details/Details";
import Protected from "./components/Protected";
import { ProSidebarProvider } from "react-pro-sidebar";
import subheader from "./components/subheader";
import { useEffect, useState } from "react";
import UpdateDetails from "./pages/details/UpdateDetails";
import Sizes from "./pages/sizes/Sizes";
import AddSize from "./pages/sizes/AddSize";
import UpdateSize from "./pages/sizes/UpdateSize";
import AddDetails from "./pages/details/AddDetails";

function App() {
  // const accessToken = localStorage.getItem("access-token");
  // console.log("accessToken:", accessToken);

  // const [isLoggedIn, setIsLoggedIn] = useState(null);
  // console.log("isLoggedIn:", isLoggedIn);

  // useEffect(() => {
  //   if (accessToken !== null) {
  //     setIsLoggedIn(true);
  //   }
  // }, [accessToken]);

  return (
    <>
      <ProSidebarProvider>
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <Protected>
                    <Sidebar />
                  </Protected>
                }
              >
                <Route
                  index
                  element={
                    <Protected>
                      <Dashboard />
                    </Protected>
                  }
                />
                <Route
                  path="/categories"
                  element={
                    <Protected>
                      <Categories />
                    </Protected>
                  }
                />
                <Route path="/add-category" element={<AddCategory />} />
                <Route
                  path="/update-category/:id"
                  element={<UpdateCategory />}
                />
                <Route
                  path="/detail-category/:id"
                  element={<DetailCategory />}
                />
                <Route path="/products" element={<Products />} />
                <Route path="/add-product" element={<AddProduct />} />
                <Route path="/detail-product/:id" element={<DetailProduct />} />
                <Route path="/update-product/:id" element={<UpdateProduct />} />
                <Route path="/users" element={<Users />} />
                <Route path="/detail-user/:id" element={<DetailUser />} />
                <Route path="/notification" element={<Notification />} />
                <Route path="/details" element={<Details />} />
                <Route path="/add-detail" element={<AddDetails />} />
                <Route path="/update-detail" element={<UpdateDetails />} />
                <Route path="/sizes" element={<Sizes />} />
                <Route path="/add-size" element={<AddSize />} />
                <Route path="/update-size/:id" element={<UpdateSize />} />
              </Route>

              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              {/* <Route path="*" element={<Errorpage />} /> */}
            </Routes>
          </BrowserRouter>
        </div>
      </ProSidebarProvider>
    </>
  );
}

export default App;
