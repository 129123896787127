import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Subheader from "../../components/subheader";
import { FcAbout } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { TfiLocationPin } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { BiTimeFive } from "react-icons/bi";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { validate } from "../../components/auth";
import { useQuery } from "react-query";

const Details = () => {
  // Get Admin Detail
  const getAdminDetail = async () => {
    // return await axios.get(`${Domain}/admin/product`,  {
    return validate.get(`/contact`);
  };

  const { isLoading, isError, isSuccess, data, refetch } = useQuery(
    "getAdminDetail",
    getAdminDetail
  );

  console.log("isError:", isError);
  console.log("isSuccess:", isSuccess);
  console.log("isLoading:", isLoading);
  console.log("data:", data);
  const navigate = useNavigate();

  const [contact, setContact] = useState();
  console.log("contact:", contact);
  useEffect(() => {
    setContact(data?.data?.contact[0]);
  }, [data]);
  return (
    <>
      <ToastContainer />

      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-5 rounded"
            xl="11"
            style={
              {
                // backgroundColor: "white",
                //   paddingTop: "2%",
                // // paddingLeft: "5%",
                // // paddingRight: "5%",
                // boxShadow: "1px 1px 5px 1px #BFBFBF",
                //   boxShadow: "0px 0px 5px 1px #B1b2b2",
              }
            }
          >
            <Row className="d-flex justify-content-between">
              <Col xxl="3">
                {" "}
                <h5 className="text-start font-weight-500 mb-4">Details</h5>
              </Col>
              <Col xxl="3">
                {" "}
                <Button
                  variant="primary"
                  type="submit"
                  // className="users-addbutton"
                  className="full-button w-100"
                  onClick={() => navigate("/add-detail")}
                >
                  + Add Details
                </Button>
              </Col>
            </Row>

            <Form
            //  onSubmit={(e) => handleProduct(e)}
            >
              <Row className="px-1 rounded">
                <Col
                  //   xxl="6"
                  className="p-5 mx-3 my-3 rounded"
                  style={{
                    boxShadow: "0px 1px 35px #e6e6e6",
                    backgroundColor: "white",
                  }}
                >
                  <h6 className="text-start family-jost fs-22 font-400">
                    {" "}
                    The Office{" "}
                  </h6>
                  <div className="d-flex mt-4">
                    <p className="my-auto family-jost font-400">
                      <TfiLocationPin color="#a6c76c" fontSize={24} />
                    </p>
                    <p className="text-start mx-3 my-auto family-jost fs-18 text-gray-77 font-400">
                      {contact?.officeAddress}
                    </p>
                  </div>

                  <div className="d-flex mt-4">
                    <p className="my-auto family-jost font-400">
                      <BsTelephone color="#a6c76c" fontSize={24} />
                    </p>
                    <p className="text-start mx-3 my-auto family-jost fs-18 text-gray-77 font-400">
                      {contact?.phone}
                    </p>
                  </div>

                  <div className="d-flex mt-4">
                    <p className="my-auto family-jost font-400">
                      <HiOutlineMail color="#a6c76c" fontSize={24} />
                    </p>
                    <p className="text-start mx-3 my-auto family-jost fs-18 text-gray-77 font-400">
                      {contact?.email}
                    </p>
                  </div>
                </Col>
                <Col
                  // xxl="3"
                  className="p-5 mx-3 my-3 rounded"
                  style={{
                    boxShadow: "0px 1px 35px #e6e6e6",
                    backgroundColor: "white",
                  }}
                >
                  <h6 className="text-start family-jost fs-22 font-400">
                    {" "}
                    Date & Time{" "}
                  </h6>
                  <div className="d-flex mt-4">
                    <p className="my-auto family-jost font-400">
                      <BiTimeFive color="#a6c76c" fontSize={24} />
                    </p>
                    <p className="text-start mx-3 my-auto family-jost fs-18 text-gray-77 font-400">
                      {contact?.date}
                    </p>
                  </div>

                  <div className="d-flex mt-4">
                    <p className="my-auto family-jost font-400">
                      <AiOutlineCalendar color="#a6c76c" fontSize={24} />
                    </p>
                    <p className="text-start mx-3 my-auto family-jost fs-18 text-gray-77 font-400">
                      {contact?.offDays}
                    </p>
                  </div>
                </Col>

                <Col
                  // xxl="3"
                  className="p-5 mx-3 my-3 rounded"
                  style={{
                    boxShadow: "0px 1px 35px #e6e6e6",
                    backgroundColor: "white",
                  }}
                >
                  <h6 className="text-start family-jost fs-22 font-400">
                    {" "}
                    Our Store{" "}
                  </h6>
                  <div className="d-flex mt-4">
                    <p className="my-auto family-jost font-400">
                      <TfiLocationPin color="#a6c76c" fontSize={24} />
                    </p>
                    <p className="text-start mx-3 my-auto family-jost fs-18 text-gray-77 font-400">
                      {contact?.storeAddress}
                    </p>
                  </div>
                  <div className="d-flex mt-4">
                    <p className="my-auto family-jost font-400">
                      <BiTimeFive color="#a6c76c" fontSize={24} />
                    </p>
                    <p className="text-start mx-3 my-auto family-jost fs-18 text-gray-77 font-400">
                      {contact?.storeHours}
                    </p>
                  </div>
                </Col>
              </Row>

              <Row className="px-1 rounded">
                <Col
                  //   xxl="12"
                  className="p-5 my-2 mx-3 rounded"
                  style={{
                    boxShadow: "0px 1px 35px #e6e6e6",
                    backgroundColor: "white",
                  }}
                >
                  <p className="text-start font-weight-500 font-size-18 family-jost my-3 pointer">
                    About us Text
                  </p>
                  <p
                    className="font-size-18 family-jost"
                    style={{ textAlign: "justify", textJustify: "inter-word" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: contact?.description.slice(0, 600),
                      }}
                    />{" "}
                    ...
                  </p>
                </Col>
              </Row>

              <Row className="px-1 my-4 rounded">
                <Col xxl="3">
                  <Button
                    className="w-100 full-button"
                    variant="success"
                    type="submit"
                    onClick={() => navigate("/update-detail")}
                    // onClick={() => mutation.mutate(addCategory)}
                  >
                    Update Details
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Details;
