import { Domain } from "../config/default";
import axios from "axios";

export const validate = axios.create({
  baseURL: `${Domain}/admin`,
  //   headers: {
  //     "Content-type": "application/json",
  //   },
  //   withCredentials: true,
});

validate.interceptors.request.use(
  async (config) => {
    // console.log("config / request:", config);
    const token = localStorage.getItem("access-token");
    // const token = getTokenFromLocalStorage();
    if (token) {
      config.headers["Authorization"] = ` bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshToken = async () => {
  try {
    const resp = await validate.get("/validate");
    console.log("refresh token response", resp);
    return resp.data;
  } catch (e) {
    console.log("Error", e);
  }
};

validate.interceptors.response.use(
  (response) => {
    // console.log("response / response: ", response);
    return response;
  },
  async function (error) {
    console.log("error:", error);
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      const resp = await refreshToken();

      console.log("response, get access token:", resp);
      const access_token = resp.response.accessToken;

      //   addTokenToLocalStorage(access_token);
      localStorage.setItem("access-token", resp.response.accessToken);
      validate.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${access_token}`;
      return validate(originalRequest);

      // refetch token on unauthorize
    } else if (error?.response?.status === 401) {
      originalRequest._retry = true;

      const resp = await refreshToken();

      console.log("response, get access token:", resp);
      const access_token = resp.response.accessToken;

      //   addTokenToLocalStorage(access_token);
      localStorage.setItem("access-token", resp.response.accessToken);
      validate.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${access_token}`;
      return validate(originalRequest);
    }
    return Promise.reject(error);
  }
);
