import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
// import Router from "next/router";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { useMutation } from "react-query";
import axios from "axios";
// import { Domain } from "@/components/config/lib";
import { Domain } from "../../config/default";
import { ToastContainer, toast } from "react-toastify";
import Subheader from "../../components/subheader";
import { useQuery } from "react-query";
import Select, { StylesConfig } from "react-select";
import { IoCloseCircleOutline } from "react-icons/io5";
import { IoAddCircleOutline } from "react-icons/io5";
import { Multiselect } from "multiselect-react-dropdown";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { validate } from "../../components/auth";

const AddProduct = () => {
  // editor state
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  console.log("editor state:", editorState);

  useEffect(() => {
    const currentContent = editorState.getCurrentContent();
    const newContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(newContent);
    console.log("raw content:", rawContent);
    // const stringifyDescription = JSON.stringify(rawContent)
    const draftedDescription = draftToHtml(rawContent);
    console.log("draftedDescription:", draftedDescription);
    setAddProduct({ ...addProduct, description: draftedDescription });
  }, [editorState]);

  const onEditorChange = (data) => {
    // console.log("description:", data)
    // console.log()
    setEditorState(data);
  };

  const [addProduct, setAddProduct] = useState({
    name: "",
    description: "",
    price: "",
    // inStock: "",
    categoryId: "",
    availability: "",
    image: "",
    url: [],
    // size: "",
    // length: "",
    // width: "",
    // height: "",
    size: [],
    tags: [],
  });
  const productFormData = new FormData();
  console.log("add product:", addProduct);

  // Get All Categories
  const getAllCategories = async () => {
    return await validate.get(`${Domain}/admin/category`);
  };

  const { isLoading, isError, isSuccess, data } = useQuery(
    "getAllCategories",
    getAllCategories
  );

  // console.log("isError:", isError);
  // console.log("isSuccess:", isSuccess);
  // console.log("isLoading:", isLoading);
  console.log("data:", data);

  const [categories, setCategories] = useState();
  useEffect(() => {
    setCategories(data?.data?.category?.rows);
    setAddProduct({
      ...addProduct,
      categoryId: categories && categories[0]?.id,
    });
  }, [data]);
  // console.log("categories:", categories);

  // handle image in form data
  function handleImage(e) {
    const files = e.target.files;
    console.log("files:", files);
    if (!files || files.length === 0) return; // No files selected
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check if the file type is an image
      if (!file.type.startsWith('image/')) {
        toast.error((`File ${file.name} is not an image. Please select only image files.`))
        return;
      }
    }

    // If file is present and does not have a documents, proceed
    setAddProduct({
      ...addProduct,
      image: [...addProduct.image, ...e.target.files],
    });
  }

  const handleDeleteImage = (index) => {
    setAddProduct({
      ...addProduct,
      image: addProduct.image.filter((_, i) => i !== index),
    });
  };

  // console.log("images outside:", productFormData.getAll("image"));

  const navigate = useNavigate();
  const [mutationResult, setMutationResult] = useState(null);
  console.log("addProduct length:", addProduct?.image?.length);
  console.log("addProduct array:", addProduct?.image);
  // addProduct?.image.length > 0 &&
  //   addProduct?.image.map((img, index) => {
  //     // console.log("imagesssssssssssss:", URL.createObjectURL(img));
  //   });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddProduct({
      ...addProduct,
      [name]: value,
    });
  };

  const handleProduct = (e) => {
    e.preventDefault();
    // console.log(addProduct);
    console.log("add product name:", addProduct?.name.length);
    if (addProduct.name.length > 300) {
      toast.error("Product Name is too long, must be 300 characters")
      return;
    }
    if (
      addProduct?.name === "" ||
      addProduct?.description === "" ||
      addProduct?.availability === ""
    ) {
      return;
    }
    // adding data in product form data
    productFormData.append("name", addProduct?.name);
    productFormData.append("description", addProduct?.description);
    if (addProduct?.price) {
      productFormData.append("price", addProduct?.price);
    }

    // productFormData.append("inStock", addProduct?.inStock);
    productFormData.append("categoryId", addProduct?.categoryId);
    productFormData.append("availability", addProduct?.availability);
    // const sizes = [
    //   {
    //     name: addProduct?.size,
    //     length: addProduct?.length,
    //     width: addProduct?.width,
    //     height: addProduct?.height,
    //   },
    // ];
    let sizesWithoutId = [...sizes];
    sizesWithoutId?.map((size, index) => {
      delete size?.id;
    });
    productFormData.append("sizes", JSON.stringify(sizesWithoutId));

    // var tagsArray = [];
    // tags?.map((tag, index) => {
    //   tagsArray.push(tag?.value);
    // });
    // console.log("tags array:", tagsArray);
    productFormData.append("tags", JSON.stringify(listOfTags));
    // productFormData.append("image", addProduct?.image);

    for (let i = 0; i < addProduct.image.length; i++) {
      productFormData.append("image", addProduct.image[i]);
    }
    // console.log("images in mutation:", productFormData.getAll("image"));
    mutation.mutate(productFormData);
  };

  // Mutations
  const mutation = useMutation((data) => {
    // console.log("images outside:", productFormData.getAll("image"));
    return validate.post(`${Domain}/admin/product`, data);
  });

  // error code 401
  if (mutation?.error?.response?.status === 401) {
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("access-token");
    toast.success("User logged out!");
    setTimeout(() => {
      navigate("/login");
    }, 100);
  }

  useEffect(() => {
    console.log("mutation:", mutation);
    if (mutation?.status === "success") {
      toast.success("Success");
      navigate("/products");
    }
    if (mutation?.status === "error") {
      toast.error(mutation?.error?.response?.data?.message);
    }
  }, [mutation?.error?.response?.data?.message, mutation?.status]);


  // react select tags
  const options = [
    { value: "king", label: "King" },
    { value: "queen", label: "Queen" },
    { value: "medium", label: "Medium" },
  ];

  const [tags, setTags] = useState({
    selectedOption: null,
  });
  // console.log("tags:", tags);
  const handleChangetag = (selectedOption) => {
    setTags(selectedOption);
  };
  const { selectedOption } = tags;

  // tags generator
  const [tagValue, setTagValue] = useState("");
  const [listOfTags, setListOfTags] = useState([]);
  // console.log("list of tags:", listOfTags);

  const generateTags = (e) => {
    console.log("key code:", e.keyCode);
    if (e.keyCode === 13 && tagValue) {
      setListOfTags([...listOfTags, tagValue]);
      setTagValue("");
    }
  };

  const deleteATag = (id) => {
    const dummy = [...listOfTags];
    dummy.splice(id, 1);
    setListOfTags(dummy);
  };

  // sizes
  const [sizes, setSizes] = useState([
    // {
    //   size: "",
    //   height: "",
    //   width: "",
    //   length: "",
    //   // id: 1,
    // },
  ]);
  // console.log("sizes:", sizes);
  const handleIncreaseSize = () => {
    setSizes([
      ...sizes,
      {
        size: "",
        height: "",
        width: "",
        length: "",
        // id: sizes[0]?.id + 1,
      },
    ]);
  };
  const handleDeleteSize = (id) => {
    if (sizes?.length === 1) {
      setShowPlus(false);
    }

    const dummy = [...sizes];
    dummy.splice(id, 1);
    setSizes(dummy);
  };

  const handleChangeSizes = (e, i) => {
    const { name, value } = e.target;
    // console.log("name:", name, "value:", value);
    const dummy = [...sizes];
    // console.log("dummy:", dummy[i][name]);
    dummy[i][name] = value;
    setSizes(dummy);
  };

  // Get All Sizes
  const getAllSizes = async () => {
    return await validate.get(`${Domain}/admin/size`);
  };

  const {
    isLoading: isLoadingSize,
    isError: isErrorSize,
    isSuccess: isSuccessSize,
    data: dataSize,
  } = useQuery("getAllSizes", getAllSizes);

  // console.log("isErrorSize:", isErrorSize);
  // console.log("isSuccessSize:", isSuccessSize);
  // console.log("isLoadingSize:", isLoadingSize);
  // console.log("dataSize:", dataSize);

  const [subselect, setSubSelect] = useState();
  console.log("subselect:", subselect);
  useEffect(() => {
    // console.log("state update called");

    setSubSelect(dataSize?.data?.size?.rows);
    // setMultiselect([...multiselect, {size: "custom value"}])
  }, [data]);

  useEffect(() => {
    if (subselect) {
      const custom = {
        size: "Custom Dimentions",
      };
      // setMultiselect([...dataSize?.data?.size?.rows, custom]);
      setMultiselect([...dataSize?.data?.size?.rows]);
    }
  }, [subselect]);

  // multi select dropdowns
  const [multiselect, setMultiselect] = useState();
  // console.log("multiselect:", multiselect);

  // multiselect[multiselect?.length - 1]?.size === "Custom Dimentions" &&
  // console.log("truee------------------");

  const [showPlus, setShowPlus] = useState(false);
  const onSelect = (selectedList, selectedItem) => {
    // console.log("selected list:", selectedList);
    // console.log("selected item:", selectedItem);

    // if (selectedItem?.size === "Custom Dimentions") {
    //   setShowPlus(true);
    // }
    if (sizes[0]?.size === "") {
      setSizes([selectedItem]);
    } else {
      setSizes([...sizes, selectedItem]);
    }
  };
  // };
  const onRemove = (remainingList, removedItem) => {
    let tobeDeleteid;
    // console.log("removed item:", removedItem);
    // if (removedItem?.size === "Custom Dimentions" || sizes?.length === 0) {
    //   setShowPlus(false);
    // }
    const { id } = removedItem;
    sizes?.map((size, index) => {
      if (size?.id === id) {
        tobeDeleteid = index;
      }
    });
    // console.log("removed id:", id);
    const dummy = [...sizes];
    dummy.splice(tobeDeleteid, 1);
    setSizes(dummy);
  };

  return (
    <>
      <ToastContainer />
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto my-4 p-5"
            xl="11"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 1px 35px #e6e6e6",
            }}
          >
            <h5
              className="text-start font-weight-500 family-jost mb-2 pointer"
              onClick={() => window.history.back()}
            >
              {" "}
              <MdOutlineKeyboardBackspace fontSize={30} />{" "}
              <span className="mx-3"> Products </span>
            </h5>

            <Form
            //  onSubmit={(e) => handleProduct(e)}
            >
              <Row className="mx-auto">
                <Col
                  xxl="8"
                  className="mx-auto p-4 pb-5"
                  style={{
                    // border: "1px solid #e6e6e6",
                    boxShadow: "0px 1px 35px #e6e6e6",
                    borderRadius: "6px",
                  }}
                >
                  <Row className="my-2">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Product Name
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter product name"
                        name="name"
                        value={addProduct?.name}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </Col>

                    <Col xxl="6">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2">
                        Category
                      </p>
                      {/* <input
                        className="input-field"
                        placeholder="Enter product price"
                        name="categoryId"
                        value={addProduct?.categoryId}
                        onChange={(e) => handleChange(e)}
                      /> */}
                      <select
                        // className="input-field"
                        className="dropdown-field"
                        name="categoryId"
                        // typeof="dropdown"
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option>-- select category --</option>
                        {/* <option value="none"> -- select category --</option> */}
                        {categories &&
                          categories?.map((category, index) => {
                            return (
                              <>
                                <option value={category?.id} key={index}>
                                  {category?.name}
                                </option>
                                {category?.subCategories &&
                                  category?.subCategories?.map(
                                    (subcategory, subindex) => {
                                      return (
                                        <option
                                          value={subcategory?.id}
                                          key={subindex}
                                        >
                                          &nbsp; - {subcategory?.name}
                                        </option>
                                      );
                                    }
                                  )}
                              </>
                            );
                          })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Price
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter product price"
                        name="price"
                        value={addProduct?.price}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </Col>
                    <Col xxl="6">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2">
                        Availability
                      </p>
                      {/* <input
                        className="input-field"
                        placeholder="Enter status of the product"
                        name="availability"
                        value={addProduct?.availability}
                        onChange={(e) => handleChange(e)}
                      /> */}
                      <select
                        className="dropdown-field"
                        name="availability"
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option value="#"> -- select availability -- </option>
                        <option value="instock">In Stock</option>
                        <option value="sale">Sale</option>
                        <option value="outofstock">Out Of Stock</option>
                      </select>
                    </Col>

                    {/* <Col xxl="6">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2">
                        In Stock
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter product price"
                        name="inStock"
                        value={addProduct?.inStock}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col> */}
                  </Row>

                  {/* <Row className="my-3">
                    <Col xxl="3">
                      <div className="d-flex">
                        <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                          Recommended
                        </p>
                        <input type="checkbox" className="mx-3" />
                      </div>
                    </Col>
                    <Col xxl="3">
                      <div className="d-flex">
                        <p className=" text-startfont-size-18 font-weight-500 family-jost my-2">
                          In Stock
                        </p>
                        <input type="checkbox" className="mx-3" />
                      </div>
                    </Col>
                  </Row> */}

                  <Row className="my-3">
                    {/* <Col xxl="6">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2">
                        Product Image
                      </p>
                      <input
                        type="file"
                        className="input-field border-0"
                        placeholder="Enter image"
                        name="image"
                        // value={addProduct?.categoryId}
                        onChange={(e) => handleImage(e)}
                        // onChange={(e) => handleChange(e)}
                        multiple
                        required
                      />
                    </Col> */}
                  </Row>

                  <Row className="mb-2">
                    <p className=" text-start font-size-18 font-weight-500 family-jost my-2">
                      Product Image
                    </p>
                    <Col lg="2">
                      <label className="custom-file-upload">
                        <input
                          type="file"
                          onChange={(e) => handleImage(e)}
                          multiple
                          required
                        />
                        <AiOutlineCloudUpload fontSize={30} color="#CF2D39" />
                        <p
                          style={{
                            color: "#CF2D39",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          upload{" "}
                        </p>
                      </label>
                    </Col>
                    {/* {addProduct?.image?.length > 0 &&
                      addProduct?.image?.map((image, index) => {
                        return (
                          <Col lg="2">
                            <img
                              src={URL.createObjectURL(image)}
                              style={{
                                width: "100%",
                                height: "89px",
                                objectFit: "contain",
                              }}
                              alt=""
                            />
                          </Col>
                        );
                      })} */}
                    {addProduct?.image &&
                      Array.from(addProduct?.image).map((file, index) => {
                        return (
                          <Col lg="2" className="p-0 mx-2 mb-3" style={{ position: "relative" }}>
                            <img
                              src={URL.createObjectURL(file)}
                              style={{
                                width: "100%",
                                height: "89px",
                                objectFit: "cover",
                              }}
                              alt=""
                            />
                            <p style={{ cursor: "pointer", position: "absolute", right: "0px", top: "0px", backgroundColor: "black", paddingLeft: "5px", paddingRight: "5px" }}
                              onClick={() => handleDeleteImage(index)}><IoMdClose color="white" /></p>
                          </Col>
                        );
                      })}
                  </Row>

                  <p className="text-start font-size-18 font-weight-500 family-jost pt-2 m-0">
                    Sizes
                  </p>

                  <Row className="">
                    <Col xxl="6">
                      <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>

                      {/* <select
                        className="input-field"
                        name="availability"
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option value="#"> -- select size type -- </option>
                        <option value="custom-dimentions">
                          Custom dimentions
                        </option>
                        {allSizes?.map((size, index) => {
                          return (
                            <option value="custom-dimentions">
                              {size?.size}
                            </option>
                          );
                        })}
                      </select> */}

                      <Multiselect
                        className="input-field my-1"
                        options={multiselect} // Options to display in the dropdown
                        selectedValues={sizes}
                        Preselected
                        value
                        to
                        persist
                        in
                        dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="size" // Property name to display in the dropdown options
                      />
                    </Col>

                    <Col xxl="1">
                      {
                        // showPlus && (
                        // size?.size === "Custom Dimentions" &&
                        <IoAddCircleOutline
                          color="green"
                          className="mt-2 pointer"
                          fontSize={36}
                          onClick={handleIncreaseSize}
                        />
                        // )
                      }
                    </Col>
                  </Row>

                  {
                    // (multiselect[multiselect?.length - 1]?.size ===
                    //   "Custom Dimentions")
                    //   ?
                    // sizes[0]?.size !== "" &&
                    sizes?.map((size, index) => {
                      return (
                        <Row className="">
                          <Col xxl="3">
                            <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>
                            <input
                              className="input-field"
                              placeholder="Enter product size"
                              name="size"
                              value={size?.size}
                              onChange={(e) => handleChangeSizes(e, index)}
                              required
                            />
                          </Col>
                          <Col xxl="2">
                            <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>
                            <input
                              className="input-field"
                              placeholder="Length"
                              name="length"
                              value={size?.length}
                              onChange={(e) => handleChangeSizes(e, index)}
                              required
                            />
                          </Col>
                          <Col xxl="2">
                            <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>
                            <input
                              className="input-field"
                              placeholder="Width"
                              name="width"
                              value={size?.width}
                              onChange={(e) => handleChangeSizes(e, index)}
                              required
                            />
                          </Col>
                          <Col xxl="2">
                            <p className=" text-start font-size-18 font-weight-500 family-jost my-2"></p>
                            <input
                              className="input-field"
                              placeholder="Height"
                              name="height"
                              value={size?.height}
                              onChange={(e) => handleChangeSizes(e, index)}
                              required
                            />
                          </Col>

                          <Col xxl="1">
                            {
                              // size?.size !== "Custom Dimentions" &&
                              // size?.size !== "" &&
                              index >= 0 && (
                                // size?.size !== "Custom Dimentions" &&
                                // size?.size !== "" &&
                                <IoCloseCircleOutline
                                  color="red"
                                  className="mt-2 pointer"
                                  fontSize={36}
                                  onClick={(e) => handleDeleteSize(index)}
                                />
                              )
                            }
                          </Col>
                        </Row>
                      );
                    })
                  }

                  <Row className="mt-3">
                    <Col xxl="12">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Tags
                      </p>
                      <div
                        className="d-flex"
                      // style={{ border: "1px solid gray" }}
                      >
                        {listOfTags?.length > 0 &&
                          listOfTags?.map((tag, index) => {
                            return (
                              <p
                                className="mx-1 px-2 d-flex py-1 rounded"
                                style={{ border: "1px solid gray" }}
                              >
                                {tag}{" "}
                                <span
                                  className="pointer"
                                  onClick={() => deleteATag(index)}
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <IoCloseCircleOutline fontSize={20} />
                                </span>
                              </p>
                            );
                          })}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xxl="12">
                      <input
                        className="input-field"
                        placeholder="Generate Tags"
                        name="generateTags"
                        value={tagValue}
                        onChange={(e) => setTagValue(e.target.value)}
                        onKeyDown={generateTags}
                      />
                      {/* <Select
                        closeMenuOnSelect={false}
                        // defaultValue={[colourOptions[0], colourOptions[1]]}
                        isMulti
                        options={options}
                        onChange={handleChangetag}
                        required
                        // styles={colourStyles}
                      /> */}
                    </Col>
                  </Row>

                  {/* <Row className="my-3">
                    <Col>
                      <Select
                        closeMenuOnSelect={false}
                        // defaultValue={[colourOptions[0], colourOptions[1]]}
                        isMulti
                        options={options}
                        onChange={handleChangetag}
                        required
                        // styles={colourStyles}
                      />
                    </Col>
                  </Row> */}

                  {/* <Row className="my-3">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Image
                      </p>
                      <input
                        className="input-field"
                        type="file"
                        placeholder="Enter product price"
                      />
                    </Col>
                  </Row> */}

                  <Row className="mt-3">
                    <Col xxl="12">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Description
                      </p>

                      <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorChange}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        required
                      // wrapperClassName="input-field"
                      // editorClassName="editorClassName"
                      // onEditorStateChange={this.onEditorStateChange}
                      />
                      {/* <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Enter description of the product"
                          name="description"
                          value={addProduct?.description}
                          onChange={(e) => handleChange(e)}
                          rows={4}
                          required
                        />
                      </Form.Group> */}
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col xxl="6" className="">
                      {
                        /* <Button className="w-100" variant="success" type="submit">
                      Submit
                    </Button> */
                        <Button
                          variant="primary"
                          // className="users-addbutton"
                          className="full-button w-100"
                          // onClick={() => navigate("/add-product")}
                          onClick={handleProduct}
                          onKeyDown={(e) => e.preventDefault()}
                        >
                          Add Product
                        </Button>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col >
        </Row >
      </Container >
    </>
  );
};

export default AddProduct;
