import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
// import Router, { useRouter } from "next/router";
import "./style.css";
import { useMutation } from "react-query";
import axios from "axios";
// import { Domain } from "@/components/config/lib";
import { Domain } from "../../config/default";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Subheader from "../../components/subheader";
import { validate } from "../../components/auth";

const UpdateSize = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // Get Single Size
  const getSingleSize = async () => {
    return await validate.get(`${Domain}/admin/size`, {
      params: { id },
    });
  };

  const { isLoading, isError, isSuccess, data } = useQuery(
    "getSingleSize",
    getSingleSize
  );

  console.log("isError:", isError);
  console.log("isSuccess:", isSuccess);
  console.log("isLoading:", isLoading);
  console.log("data:", data);

  const [updateSize, setUpdateSize] = useState();
  console.log("update size:", updateSize);

  useEffect(() => {
    setUpdateSize({
      // ...updateCategory,
      // image: data?.data?.category?.rows[0]?.image,
      // name: data?.data?.category?.rows[0]?.name,
      // description: data?.data?.category?.rows[0]?.description,
      size: data?.data?.size?.rows[0]?.size,
      length: data?.data?.size?.rows[0]?.length,
      width: data?.data?.size?.rows[0]?.width,
      height: data?.data?.size?.rows[0]?.height,
    });
  }, [data]);
  console.log("update size:", updateSize);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateSize({
      ...updateSize,
      [name]: value,
    });
  };

  const handleSize = (e) => {
    e.preventDefault();
    console.log(updateSize);
    mutation.mutate(updateSize);
  };

  // Mutations
  const mutation = useMutation((data) => {
    return validate.put(`${Domain}/admin/size/${id}`, data);
  });

  console.log("mutation:", mutation);

  useEffect(() => {
    if (mutation?.status === "success") {
      toast.success(mutation?.error?.response?.data?.message);
      //   Router.back();

      setTimeout(() => {
        window.history.back();
      }, 100);
    }
    if (mutation?.status === "error") {
      toast.error(mutation?.error?.response?.data?.message);
    }
  }, [mutation]);

  // error code 401
  if (mutation?.error?.response?.status === 401) {
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("access-token");
    // toast.success("User logged out!");
    setTimeout(() => {
      navigate("/login");
    }, 100);
  }

  return (
    <>
      <ToastContainer />
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-5 p-5 rounded"
            xl="11"
            style={{
              backgroundColor: "white",
              boxShadow: "1px 1px 1px 1px #b1b2b2",
            }}
          >
            <h5
              className="text-start font-weight-500 family-jost mb-4 pointer"
              onClick={() => window.history.back()}
            >
              {" "}
              <MdOutlineKeyboardBackspace fontSize={30} />{" "}
              <span className="mx-3"> Size </span>
            </h5>

            <Form onSubmit={(e) => handleSize(e)}>
              <Row className="mx-auto">
                <Col xxl="7" className="mx-auto">
                  <Row className="my-3">
                    <Col xxl="9">
                      <p className="font-size-18 font-weight-500 family-jost my-2 text-start">
                        Name
                      </p>
                      <input
                        name="size"
                        onChange={(e) => handleChange(e)}
                        value={updateSize?.size}
                        type="text"
                        className="input-field"
                        placeholder="Enter size name"
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col xxl="3">
                      <p className="font-size-18 font-weight-500 family-jost my-2 text-start">
                        Length
                      </p>
                      <input
                        name="length"
                        onChange={(e) => handleChange(e)}
                        value={updateSize?.length}
                        type="text"
                        className="input-field"
                        placeholder="Enter length"
                        required
                      />
                    </Col>
                    <Col xxl="3">
                      <p className="font-size-18 font-weight-500 family-jost my-2 text-start">
                        Width
                      </p>
                      <input
                        name="width"
                        onChange={(e) => handleChange(e)}
                        value={updateSize?.width}
                        type="text"
                        className="input-field"
                        placeholder="Enter width"
                        required
                      />
                    </Col>
                    <Col xxl="3">
                      <p className="font-size-18 font-weight-500 family-jost my-2 text-start">
                        Height
                      </p>
                      <input
                        name="height"
                        onChange={(e) => handleChange(e)}
                        value={updateSize?.height}
                        type="text"
                        className="input-field"
                        placeholder="Enter height"
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="my-5">
                    <Col xxl="6" className="">
                      <Button
                        className="w-100 full-button"
                        variant="success"
                        type="submit"
                        // onClick={() => mutation.mutate(addCategory)}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateSize;
