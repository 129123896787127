import React, { useState } from "react";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  NavDropdown,
} from "react-bootstrap";
// import Link from "next/link";
import { Link } from "react-router-dom";
import "./style.css";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { RxTextAlignJustify } from "react-icons/rx";
import { BiDollar } from "react-icons/bi";
import { BsCart3 } from "react-icons/bs";
import { MdCalendarMonth } from "react-icons/md";
import { MdOutlinePending } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { FiTruck } from "react-icons/fi";
import Subheader from "../components/subheader";

import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { point } from "chart.js";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";

const Dashboard = () => {
  // Bar Chart
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
  );

  const optionsBar = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const dataBar = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Dataset 2",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  // Doughnut Chart
  Chart.register(ArcElement);
  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Line Chart
  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
    },
  };

  const labelsLine = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const dataLine = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Dataset 2",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  // Users
  const [users, setUsers] = useState([
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
      order: "pending"
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
      order: "success"
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
      order: "rejected"
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
      order: "success"
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
      order: "pending"
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
      order: "pending"
    },
    {
      name: "John Depp",
      email: "jhon@gmail.com",
      phone: 234234234234,
      city: "California",
      order: "success"
    },
  ]);

  const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
    useProSidebar();
  return (
    <>
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-5 rounded"
            xl="11"
            style={
              {
                // backgroundColor: "white",
                // // paddingTop: "5%",
                // // paddingLeft: "5%",
                // // paddingRight: "5%",
                // boxShadow: "1px 1px 5px 1px #BFBFBF",
              }
            }
          >
            <h5 className="text-start font-weight-500 mb-4">Dashboard</h5>

            <Row>
              <Col className="px-5 py-4 mx-3 rounded dashboard-card-1">
                <BiDollar size={50} color="white" className="mt-2" />
                <h3 className="font-size-24 text-white pt-1 my-2">Total Revenue</h3>
                <p className="font-size-30 text-white font-weight-800 my-0 py-0">
                  239
                </p>
              </Col>

              <Col className="px-5 py-4 mx-3 rounded dashboard-card-2">
                <BsCart3 size={50} color="white" className="mt-2" />
                <h3 className="font-size-24 text-white pt-1 my-2">Total Orders</h3>
                <p className="font-size-30 text-white font-weight-800 my-0 py-0">
                  234
                </p>
              </Col>

              <Col className="px-5 py-4 mx-3 rounded dashboard-card-3">
                <MdCalendarMonth size={50} color="white" className="mt-2" />
                <h3 className="font-size-24 text-white pt-1 my-2">
                  Orders This Month
                </h3>
                <p className="font-size-30 text-white font-weight-800 my-0 py-0">
                  5
                </p>
              </Col>
            </Row>

            <Row className="my-4">
              <Col
                className="mx-3 rounded"
                style={{ boxShadow: "1px 1px 5px 1px #bfbfbf"  }}
              >
                <Row className="py-4">
                  <Col xxl="3">
                    <MdOutlinePending
                      size={70}
                      color="red"
                      className="p-3 rounded-circle"
                      style={{ backgroundColor: "#dc354587" }}
                    />
                  </Col>
                  <Col xxl="9">
                    <p className="text-start font-size-20 my-0 text-secondary">
                      Pending Orders
                    </p>
                    <p className="text-start font-size-24 font-weight-600 my-0">
                      79
                    </p>
                  </Col>
                </Row>
                {/* <Table>
                  <tr>
                    <td rowSpan={2}>
                      <MdOutlinePending size={50} color="Black" className="mt-2" />
                    </td>
                    <td>
                      <p>Pending Orders</p>
                    </td>
                  </tr>
                  <tr>
                    <td>79</td>
                  </tr>
                </Table> */}
              </Col>

              <Col
                className="mx-3 rounded"
                style={{ boxShadow: "1px 1px 5px 1px #bfbfbf" }}
              >
                <Row className="py-4">
                  <Col xxl="3">
                    <TiTick
                      size={70}
                      color="blue"
                      className="p-3 rounded-circle"
                      style={{ backgroundColor: "lightblue" }}
                    />
                  </Col>
                  <Col xxl="9">
                    <p className="text-start font-size-20 my-0 text-secondary">
                      Confirmed Orders
                    </p>
                    <p className="text-start font-size-24 font-weight-600 my-0">
                      28
                    </p>
                  </Col>
                </Row>
              </Col>

              <Col
                className="mx-3 rounded"
                style={{ boxShadow: "1px 1px 5px 1px #bfbfbf" }}
              >
                <Row className="py-4">
                  <Col xxl="3">
                    <FiTruck
                      size={70}
                      color="green"
                      className="p-3 rounded-circle"
                      style={{ backgroundColor: "#0080004f" }}
                    />
                  </Col>
                  <Col xxl="9">
                    <p className="text-start font-size-20 my-0 text-secondary">
                      Delievered Orders
                    </p>
                    <p className="text-start font-size-24 font-weight-600 my-0">
                      11
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* <Row className="mx-1">
              <Col xxl="12" className="dashboard-graph m-0 p-3">
                <Table className="w-100">
                  <thead>
                    <tr className="" style={{ backgroundColor: "#f3f3f3" }}>
                      <th className="heading text-start">Sr.no</th>
                      <th className="heading text-start">Name</th>
                      <th className="heading text-start">Email</th>
                  
                      <th className="heading text-start">Status</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {users?.length === 0 ? (
                      <p>No Users present in the Database</p>
                    ) : (
                      users?.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td className="text text-start">{index + 1}</td>
                            <td className="text text-start">{user?.name}</td>
                            <td className="text text-start">{user?.email}</td>
                    
                            <td className="text text-start">
                              <p
                                className={
                                  user?.status === "completed"
                                  ? "m-0 p-0 font-weight-600 rounded text-start text-success"
                                  : "m-0 p-0 font-weight-600 rounded text-start text-secondary"
                                
                                }
                              >
                                {" "}
                                {user?.status}{" "}
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row> */}
            <Table
              className="w-100 bg-light my-4"
              style={{
                boxShadow: "0px 1px 35px #e6e6e6",
                borderRadius: "6px",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#e5e5e5" }}>
                <th className="heading text-start px-3 py-3">Sr.</th>
                  <th className="heading text-start px-3 py-3">Name</th>
                  <th className="heading text-start px-3 py-3">Email</th>
                  <th className="heading text-start px-3 py-3">Phone</th>
                  <th className="heading text-start px-3 py-3">City</th>
                  <th className="heading text-start px-3 py-3">Status</th>
                  {/* <th className="heading text-start px-3 py-3 max-width-100">
                    Recommended
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {users?.length === 0 ? (
                  <p>No Users present in the Database</p>
                ) : (
                  users?.map((user, index) => {
                    return (
                      
                      <tr key={index}>
                        <td className="text text-start px-3 pt-3 family-jost font-size-15 font-weight-600">
                          {index+1}
                        </td>
                        <td className="text text-start px-3 pt-3 family-jost font-size-15 font-weight-600">
                          {user?.name}
                        </td>
                        <td className="text text-start px-3 py-3 family-jost font-size-15 d-flex">
                          {user?.email}
                        </td>
                        <td className="text text-start px-3 py-3 family-jost font-size-15">
                          {user?.phone}
                        </td>
                        <td className="text text-start px-3 py-3 family-jost font-size-15">
                          {user?.city}
                        </td>
                        <td className="text text-start px-3 py-3 family-jost font-size-15">
                          <span
                            className={
                              user?.order === "success"
                                ? "px-3 py-1 text-light family-jost bg-success rounded-pill"
                                : user?.order === "pending"
                                ? "px-3 py-1 text-light family-jost bg-secondary rounded-pill"
                                : "px-3 py-1 text-light family-jost bg-danger rounded-pill"
                            }
                          >
                            {user?.order}
                          </span>
                        </td>
                        {/* <td className="text text-start px-3 py-3 font-weight-600 family-jost text-primary py-3 font-size-15 max-width-100">
                          {product?.recommended === true ? "✓" : "X"}{" "}
                        </td> */}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      {/* <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
       <Subheader />
        <Row>
          <Col
            className="mx-auto mt-5 pb-5 rounded"
            xl="10"
            style={{
              backgroundColor: "white",
              paddingTop: "5%",
              paddingLeft: "5%",
              paddingRight: "5%",
              boxShadow: "1px 1px 5px 1px #BFBFBF",
            }}
          >
            <h5 className="text-start mb-4">Furniture E-2commerce Dashboard</h5>

            <Row>
              <Col xxl="3" className="dashboard-graph">
                <Doughnut data={data} />
              </Col>
              <Col xxl="3" className="dashboard-graph">
                <Bar options={optionsBar} data={dataBar} />
              </Col>

              <Col xxl="3" className="dashboard-graph">
                <Line options={optionsLine} data={dataLine} />
              </Col>

              <Col xxl="10" className="dashboard-graph mt-4">
                <Table className="w-100">
                  <thead>
                    <tr>
                      <th>Sr.no</th>
                      <th className="heading">Name</th>
                      <th className="heading">Email</th>
                      <th className="heading">Date of birth</th>
                      <th className="heading">Phone No</th>
                      <th className="heading">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.length === 0 ? (
                      <p>No Users present in the Database</p>
                    ) : (
                      users?.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td className="text">{index + 1}</td>
                            <td className="text">{user?.name}</td>
                            <td className="text">{user?.email}</td>
                            <td className="text">{user?.dob}</td>
                            <td className="text">{user?.phone}</td>
                            <td className="text">
                              <p
                                className={
                                  user?.status === "completed"
                                    ? "m-0 p-0 bg-success rounded text-center text-white"
                                    : "m-0 p-0 bg-secondary rounded text-center text-white"
                                }
                              >
                                {" "}
                                {user?.status}{" "}
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container> */}
    </>
  );
};

export default Dashboard;
