import React, { useState } from "react";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import apple from "../../assets/apple.jpg";
import banana from "../../assets/banana.jpg";
// import Image from "next/image";
import "./style.css";
// import Router from "next/router";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Subheader from "../../components/subheader";
import { Form } from "react-bootstrap";
import { validate } from "../../components/auth";
import { Domain } from "../../config/default";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const DetailCategory = () => {
  const [isReadMore, setIsReadMore] = useState(true);
  const navigate = useNavigate();

  // Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    // setDeleteId(id);
    setShow(true);
  };

  // get category details
  const categoryFormData = new FormData();
  const { id } = useParams();

  // Get Single Category
  const getSingleCategy = async () => {
    return await validate.get(`${Domain}/admin/category`, {
      params: { id },
    });
  };

  const { isLoading, isError, isSuccess, data } = useQuery(
    "getSingleCategy",
    getSingleCategy
  );

  console.log("isError:", isError);
  console.log("isSuccess:", isSuccess);
  console.log("isLoading:", isLoading);
  console.log("data:", data);
  console.log("data 2:", data?.data?.category?.rows[0]?.subCategories);

  const [updateCategory, setUpdateCategory] = useState();
  console.log("update category:", updateCategory);

  useEffect(() => {
    setUpdateCategory({
      ...updateCategory,
      id: data?.data?.category?.rows[0]?.id,
      image: data?.data?.category?.rows[0]?.image,
      name: data?.data?.category?.rows[0]?.name,
      description: data?.data?.category?.rows[0]?.description,
      subcategories: data?.data?.category?.rows[0]?.subCategories,
    });
  }, [data]);
  console.log("update category:", updateCategory);

  return (
    <>
      <ToastContainer />
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-5 p-5 rounded"
            xl="11"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 1px 35px #e6e6e6",
            }}
          >
            <h5
              className="text-start font-weight-500 family-jost mb-4 pointer"
              onClick={() => window.history.back()}
            >
              {" "}
              <MdOutlineKeyboardBackspace fontSize={30} />{" "}
              <span className="mx-3"> Categories </span>
            </h5>

            <Row
              className="mx-auto py-4 my-0"
              style={{ boxShadow: "0px 1px 35px #e6e6e6" }}
            >
              <Col xxl="4" className="mt-0 mx-auto">
                <img
                  src={updateCategory?.image}
                  alt="img"
                  style={{ height: "280px", objectFit: "contain" }}
                  className="w-100"
                />
              </Col>

              <Col xxl="7" className="mx-auto my-1">
                <Row className="mb-3">
                  <Col xxl="6" className="">
                    <p className="text-start font-size-18 font-weight-500 family-jost mb-2">
                      Category Name:
                    </p>
                    <p className="text-start text-secondary font-size-18 font-weight-500 family-jost mb-2">
                      {updateCategory?.name}
                    </p>
                  </Col>
                </Row>

                <Row className="my-3">
                  <Col xxl="12" className="">
                    <p className="text-start font-size-18 font-weight-500 family-jost mb-2">
                      Description: 2
                    </p>
                    {isReadMore ? (
                      <span className="d-flex">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: updateCategory?.description?.slice(0, 200),
                          }}
                        />
                        {updateCategory?.description?.length > 200 && (
                          <p
                            style={{ cursor: "pointer" }}
                            className="text-primary"
                            onClick={() => setIsReadMore(!isReadMore)}
                          >
                            {" "}
                            ... read more{" "}
                          </p>
                        )}
                      </span>
                    ) : (
                      <span>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: updateCategory?.description,
                          }}
                        />
                        <p
                          style={{ cursor: "pointer" }}
                          className="text-primary"
                          onClick={() => setIsReadMore(!isReadMore)}
                        >
                          {" "}
                          read less{" "}
                        </p>
                      </span>
                    )}
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col xxl="6" className="">
                    <Button
                      className="w-100 full-button"
                      variant="success"
                      type="submit"
                      onClick={() =>
                        navigate(`/update-category/${updateCategory?.id}`)
                      }
                      // onClick={() => mutation.mutate(addCategory)}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            {updateCategory?.subcategories?.length > 0 && (
              <p className="text-center text-dark px-5 mx-3 font-size-20 font-weight-500 family-jost my-5">
                Sub Categories of {updateCategory?.name}
              </p>
            )}

            {/* Sub category */}

            <Row className="mx-auto my-0">
              {updateCategory?.subcategories?.map((subcategory, index) => {
                return (
                  <Col
                    xxl="4"
                    // className="my-2 px-4 py-4"
                    // style={{ boxShadow: "0px 1px 35px #e6e6e6" }}
                  >
                    {/* <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                          Category Icon
                        </p> */}
                    <div
                      className="my-2 px-4 py-4"
                      style={{ boxShadow: "0px 1px 35px #e6e6e6" }}
                    >
                      <img
                        src={subcategory?.image}
                        alt="img"
                        style={{ height: "250px", objectFit: "contain" }}
                        className="w-100"
                      />

                      <div className="d-flex mt-3 mb-1">
                        <p className="text-start font-size-18 font-weight-500 family-jost mb-2">
                          Category:
                        </p>
                        <p className="text-start text-secondary font-size-18 font-weight-500 family-jost px-2 mb-2">
                          {subcategory?.name}
                        </p>
                      </div>

                      <div className="my-1">
                        <p className="text-start font-size-18 font-weight-500 family-jost mb-2">
                          Description:
                        </p>
                        <div
                          className="text-start text-secondary font-size-18 font-weight-500 family-jost px-2 mb-2"
                          dangerouslySetInnerHTML={{
                            __html: subcategory?.description,
                          }}
                        />
                      </div>

                      <Row>
                        <Col xxl="6">
                          <Button
                            className="w-100 full-button"
                            variant="success"
                            type="submit"
                            // onClick={() => mutation.mutate(addCategory)}
                            onClick={() =>
                              navigate(`/update-category/${subcategory?.id}`)
                            }
                          >
                            Update
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal className="family-jost" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this category?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger">Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DetailCategory;
