import React from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { MdAdminPanelSettings } from "react-icons/md";

const Subheader = () => {
  const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
    useProSidebar();
  return (
    <>
      <div
        className="w-100 py-3 px-3 subheader"
        style={{ boxShadow: "0px 1px 10px lightgray" }}
      >
        <MdAdminPanelSettings
          fontSize={30}
          className="pointer"
          onClick={() => collapseSidebar()}
        />{" "}
        <h5 className="mx-3 mt-1 font-weight-800"> INTEGRAL FURNITURE </h5>
      </div>
    </>
  );
};

export default Subheader;
