import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  NavDropdown,
  Dropdown,
  ButtonGroup,
  Table,
  Button,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import { ImBin } from "react-icons/im";
import { IoMdEye } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { BsPen } from "react-icons/bs";
// import Link from "next/link";
import { Link } from "react-router-dom";
import "./style.css";
// import Image from "next/image";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useQuery } from "react-query";
import axios from "axios";
import { Domain } from "../../config/default";
import { Spinner } from "react-bootstrap";
import { useMutation } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import Router, { useRouter } from "next/router";
import Subheader from "../../components/subheader";
import categoryImage from "../../assets/brocli.png";
import { validate } from "../../components/auth";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const Sizes = () => {
  // User is currently on this page
  const [currentPage, setCurrentPage] = useState(0);
  console.log("current page:", currentPage);
  const [recordsPerPage] = useState(10);
  const location = useLocation();
  const [variables, setVariables] = useState();
  const [total, setTotal] = useState();

  useEffect(() => {
    // console.log("current page updated");
    // Object.keys(locationUrl).length !== 0 &&
    setVariables({ ...variables, page: currentPage });
    console.log("current page updated--");
  }, [currentPage]);

  // location url query string
  var locationUrl = queryString.parse(location.search);
  console.log("locations:", locationUrl);
  // console.log("Object.keys(locationUrl).length:", Object.keys(locationUrl).length);

  useEffect(() => {
    console.log("variables updated--");
    if (variables === undefined) {
    } else {
      const newUrl = queryString.stringify(variables);
      navigate({ search: newUrl });
    }

    getAllSizes();
    // refetch();
  }, [variables]);

  useEffect(() => {
    console.log("variable set from location url called");
    if (Object.keys(locationUrl).length !== 0 && variables === undefined) {
      console.log("variables set");
      setVariables(locationUrl);
      setCurrentPage(locationUrl?.page);
    }
  }, []);

  const navigate = useNavigate();
  // Get All Sizes
  const getAllSizes = async () => {
    if (variables?.size === "") {
      delete variables.size;
    }
    return await validate
      .get(`${Domain}/admin/size`, {
        params: variables,
      })
      .then((response) => {
        console.log("response:", response);
        setSizes(response?.data?.size?.rows);
        setTotal(response?.data?.size?.count);
      });
  };

  const { isLoading, isError, isSuccess, data, refetch } = useQuery(
    "getAllSizes",
    getAllSizes
  );

  console.log("isError:", isError);
  console.log("isSuccess:", isSuccess);
  console.log("isLoading:", isLoading);
  console.log("data:", data);

  // pagination
  const [sizes, setSizes] = useState();
  console.log("sizes:", sizes);

  // indices of page
  // const indexOfLastRecord = parsed?.page * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const nPages = total && Math.ceil(total / recordsPerPage);
  const pageNumbers = nPages && [...Array(nPages + 1).keys()]?.slice(1);
  console.log("page Numbers:", pageNumbers, nPages);

  const handlePrev = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    console.log(pageNumbers?.length);
    console.log(currentPage);
    if (pageNumbers?.length > currentPage + 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    console.log("state update called");
    setSizes(data?.data?.size?.rows);
  }, [data]);

  // Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const [deleteId, setDeleteId] = useState();

  const handleDelete = () => {
    mutation.mutate(deleteId);
    handleClose();
  };

  //  Delete Mutations
  const mutation = useMutation(
    (id) => {
      return validate.delete(`${Domain}/admin/size/${id}`);
    }
    // {
    //   onSuccess: () => {
    //     // Invalidate and refetch
    //     queryClient.invalidateQueries("getAllCategories");
    //   },
    // }
  );

  //   const router = useRouter();
  console.log("mutation:", mutation);
  useEffect(() => {
    if (mutation?.status === "success") {
      toast.success(mutation?.error?.response?.data?.message || "Success");
      // toast.success(mutation?.error?.response?.data?.message || "Success");
      setTimeout(() => {
        // router.reload();
        window.location.reload();
      }, 500);
    }
    if (mutation?.status === "error") {
      toast.error(mutation?.error?.response?.data?.message);
    }
  }, [mutation]);

  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  // error code 401
  if (mutation?.error?.response?.status === 401) {
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("access-token");
    toast.success("User logged out!");
    setTimeout(() => {
      navigate("/login");
    }, 100);
  }

  // search via size name
  const [search, setSearch] = useState("");
  const handleSearchChange = () => {
    setTimeout(() => {
      setVariables({ ...variables, size: search });
    }, 100);
  };

  const handleEnterButton = (e) => {
    console.log("button:", e.keyCode);
    if (e.keyCode === 13) {
      handleSearchChange();
    }
  };

  // useEffect(() => {
  //   handleSearchChange();
  // }, [search]);

  const handleClearSearch = () => {
    const dummy = { ...variables };
    delete dummy.size;
    setVariables(dummy);
    setSearch("");
  };

  return (
    <>
      <ToastContainer />
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-5 rounded"
            xl="11"
            style={
              {
                // backgroundColor: "white",
                // // paddingTop: "5%",
                // // paddingLeft: "5%",
                // // paddingRight: "5%",
                // boxShadow: "1px 1px 5px 1px #BFBFBF",
              }
            }
          >
            <h5 className="text-start font-weight-500 mb-4">Sizes </h5>
            <Row
              className="d-flex justify-content-between rounded-3 mx-0 my-3 py-3"
              style={{
                boxShadow: "0px 1px 35px #e6e6e6",
                backgroundColor: "white",
              }}
            >
              <Col xxl="3" className="pt-2">
                <div className="search-bar">
                  <input
                    className="search-field"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search..."
                    onKeyDown={handleEnterButton}
                  />
                  {!search && (
                    <AiOutlineSearch className="products-search-bar pointer" />
                  )}

                  {search && (
                    <AiOutlineClose
                      className="products-search-bar pointer"
                      onClick={(e) => handleClearSearch(e)}
                    />
                  )}
                </div>
              </Col>
              {/* <Col xxl="2" className="pt-2">
                <div className="d-flex">
                  <p className="mx-0 mt-2 p-0">By</p>

                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Name"
                    className="search-dropdown mx-2"
                  >
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  </DropdownButton>

                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Category"
                    className="search-dropdown mx-2"
                  >
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  </DropdownButton>
                </div>
              </Col> */}

              {/* <Col xxl="3" className=" pt-2">
                <DateRangePicker
                  initialSettings={{
                    startDate: "1/1/2014",
                    endDate: "3/1/2014",
                  }}
                >
                  <button className="date-picker">
                    --/--/-- &nbsp; &nbsp; &nbsp; &nbsp; --/--/--
                  </button>
                </DateRangePicker>
              </Col> */}

              {/* <Col xxl="1" className=" pt-2">
                <AiOutlineClose fontSize={24} className="mt-2" />
              </Col> */}

              <Col xxl="3" className=" pt-2">
                <Button
                  variant="primary"
                  type="submit"
                  // className="users-addbutton"
                  className="full-button w-100"
                  // href="/add-category"
                  onClick={() => navigate("/add-size")}
                >
                  + Add Size
                </Button>
              </Col>
            </Row>

            {isLoading && (
              <div className="d-flex">
                <Spinner animation="border" className="my-3 mx-auto" />
              </div>
            )}
            <Table
              // responsive
              className="w-100 bg-light my-4"
              style={{
                // boxShadow: "1px 1px 3px 1px #b1b2b2",
                boxShadow: "0px 1px 35px #e6e6e6",
                borderRadius: "6px",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#e5e5e5" }}>
                  <th className="heading px-3 py-3 text-start">Name</th>
                  <th className="heading px-3 py-3 text-start">Length</th>
                  <th className="heading px-3 py-3 text-start">Width</th>
                  <th className="heading px-3 py-3 text-start">Height</th>

                  {/* <th className="heading px-3 py-3">Detail</th> */}
                  {/* <th className="heading px-3 py-3 text-start">Detail</th> */}
                  <th className="heading px-3 py-3 text-start">Action</th>
                  <th className="heading px-3 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {sizes?.length === 0 ? (
                  <p>No Sizes present in the Database</p>
                ) : (
                  sizes?.map((size, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-start px-3 py-3 family-jost font-size-15">
                          {size?.size}
                        </td>
                        <td className="text-start px-3 py-3 family-jost font-size-15">
                          {size?.length}
                        </td>
                        <td className="text-start px-3 py-3 family-jost font-size-15">
                          {size?.width}
                        </td>
                        <td className="text-start px-3 py-3 family-jost font-size-15">
                          {size?.height}
                        </td>
                        <td className="text-start px-3 py-3">
                          <BsPen
                            size={15}
                            color="black"
                            className=""
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`/update-size/${size?.id}`)}
                          />
                        </td>
                        <td className="text-start px-3 py-3 ">
                          <ImBin
                            size={15}
                            color="red"
                            className=""
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShow(size?.id)}
                            // onClick={() => handleDeleteForm(form?.id)}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>

            <Row>
              <Col xxl="5" xl="6" lg="6" md="6" sm="8" className="mx-auto">
                <nav className="my-2">
                  <ul
                    className="pagination justify-content-center"
                    style={{ overflowX: "auto" }}
                  >
                    <li className="page-item text-dark">
                      <p
                        onClick={() => handlePrev()}
                        className="text px-3 py-1 rounded mx-3 pagination-button"
                      >
                        Prev
                      </p>
                    </li>
                    {pageNumbers !== 0 &&
                      pageNumbers?.map((pgNumber) => (
                        <li key={pgNumber}>
                          <p
                            className={`text page-item mx-1 px-3 py-1 ${
                              currentPage == pgNumber - 1 ? "activePage" : ""
                            } `}
                            style={{
                              cursor: "pointer",
                              boxShadow: "1px 1px 2px gray",
                            }}
                            onClick={() => setCurrentPage(pgNumber - 1)}
                          >
                            {pgNumber}
                          </p>
                        </li>
                      ))}

                    <li className="page-item">
                      <p
                        onClick={() => handleNext()}
                        className="text px-3 py-1 rounded mx-3 pagination-button"
                      >
                        Next
                      </p>
                    </li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal className="family-jost" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this category?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Sizes;
