import React from "react";
import { Row, Col, Container, Button, Table } from "react-bootstrap";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import apple from "../../assets/apple.jpg";
import banana from "../../assets/banana.jpg";
// import Image from "next/image";
import "./style.css";
// import Router from "next/router";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import Subheader from "../../components/subheader";
import masala from "../../assets/gram masala.jpg";

const DetailUser = () => {
  // react-slack
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };
  console.log("detail called");
  const navigate = useNavigate();

  const userOrder = [
    {
      name: "john Khan",
      city: "New Shakrial",
      address: "House No P-22, Block D, Islamabad",
      phone: 34234243,
      price: 3400,
      status: "success",
    },
    {
      name: "john Khan",
      city: "New Shakrial",
      address: "House No P-22, Block D, Islamabad",
      phone: 34234243,
      price: 3400,
      status: "pending",
    },
    {
      name: "john Khan",
      city: "New Shakrial",
      address: "House No P-22, Block D, Islamabad",
      phone: 34234243,
      price: 3400,
      status: "cancelled",
    },
  ];

  return (
    <>
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-5 rounded"
            xl="11"
            style={
              {
                // backgroundColor: "white",
                // // paddingTop: "5%",
                // // paddingLeft: "5%",
                // // paddingRight: "5%",
                // boxShadow: "1px 1px 5px 1px #BFBFBF",
              }
            }
          >
            <h5
              className="text-start font-weight-500 family-jost mb-4 pointer"
              onClick={() => window.history.back()}
            >
              {" "}
              <MdOutlineKeyboardBackspace fontSize={30} />{" "}
              <span className="mx-3 family-jost"> Users </span>
            </h5>
            <Row
              className="bg-light rounded-3 mx-0 my-3 py-3"
              style={{ boxShadow: "1px 1px 1px 1px #B1b2b2" }}
            >
              <Col xxl="3" className="pt-2">
                <Row>
                  <Col xxl="5">
                    <img src={masala} className="w-100" />
                  </Col>
                  <Col xxl="7" className="">
                    <p className="family-jost font-size-18 font-weight-600 text-start">
                      John Stephen Rajpoot
                    </p>
                    <p className="family-jost font-size-18 text-start">
                      jhonstephen@gmail.com
                    </p>
                    <p className="family-jost font-size-18 text-start">+92 8988 088234</p>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Table
              className="w-100 bg-light my-4"
              style={{
                boxShadow: "1px 1px 1px 1px #B1b2b2",
                borderRadius: "6px",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#e5e5e5" }}>
                  <th className="heading text-start px-3 py-3">Name</th>
                  <th className="heading text-start px-3 py-3">City</th>
                  <th className="heading text-start px-3 py-3">Address</th>
                  <th className="heading text-start px-3 py-3">Phone</th>
                  <th className="heading text-start px-3 py-3">Price</th>
                  <th className="heading text-start px-3 py-3">Status</th>
                  {/* <th className="heading text-start px-3 py-3 max-width-100">
                    Recommended
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {userOrder?.length === 0 ? (
                  <p>No Order present in the Database</p>
                ) : (
                  userOrder?.map((order, index) => {
                    return (
                      <tr key={index}>
                        <td className="text text-start px-3 pt-3 family-jost font-size-15 font-weight-600">
                          {order?.name}
                        </td>
                        <td className="text text-start px-3 py-3 family-jost font-size-15 d-flex max-width-200">
                          {order?.city}
                        </td>
                        <td className="text text-start px-3 py-3 family-jost font-size-15">
                          {order?.address}
                        </td>
                        <td className="text text-start px-3 py-3 family-jost font-size-15">
                          {order?.phone}
                        </td>
                        <td className="text text-start px-3 py-3 family-jost font-size-15">
                          {order?.price}
                        </td>
                        <td className="text text-start px-3 py-3 family-jost font-size-15">
                          <span
                            className={
                              order?.status === "success"
                                ? "px-3 py-1 text-light family-jost bg-success rounded-pill"
                                : order?.status === "pending"
                                ? "px-3 py-1 text-light family-jost bg-secondary rounded-pill"
                                : "px-3 py-1 text-light family-jost bg-danger rounded-pill"
                            }
                          >
                            {order?.status}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailUser;
