import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  NavDropdown,
  Dropdown,
  ButtonGroup,
  Table,
  Button,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import { ImBin } from "react-icons/im";
import { IoMdEye } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { BsPen } from "react-icons/bs";
// import Link from "next/link";
import { Link } from "react-router-dom";
import "./style.css";
// import Image from "next/image";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useQuery } from "react-query";
import axios from "axios";
import { Domain } from "../../config/default";
import { Spinner } from "react-bootstrap";
import { useMutation } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import Router, { useRouter } from "next/router";
import Subheader from "../../components/subheader";
import image from "../../assets/gram masala.jpg";
import { validate } from "../../components/auth";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";

const Products = () => {
  // User is currently on this page
  const location = useLocation();
  const [variables, setVariables] = useState();
  // console.log("variables:", variables);
  const [currentPage, setCurrentPage] = useState(0);
  const [total, setTotal] = useState();
  // console.log("current page:", currentPage);
  const [search, setSearch] = useState("");
  // const [debounceSearch] = useDebounce(search, 1000);
  // console.log("deabounce search:", debounceSearch)

  useEffect(() => {
    // console.log("current page updated");
    // Object.keys(locationUrl).length !== 0 &&
    setVariables({ ...variables, page: currentPage });
    // console.log("current page updated--");
  }, [currentPage]);

  // location url query string
  var locationUrl = queryString.parse(location.search);
  // console.log("locations:", locationUrl);
  // console.log("Object.keys(locationUrl).length:", Object.keys(locationUrl).length);

  useEffect(() => {
    // console.log("variables updated--");
    if (variables === undefined) {
    } else {
      const newUrl = queryString.stringify(variables);
      navigate({ search: newUrl });
    }

    getAllProducts();
    // refetch();
  }, [variables]);

  const [recordsPerPage] = useState(10);

  useEffect(() => {
    // console.log("variable set from location url called");
    if (Object.keys(locationUrl).length !== 0 && variables === undefined) {
      // console.log("variables set");
      setVariables(locationUrl);
      setCurrentPage(locationUrl?.page);
    }
  }, []);

  const navigate = useNavigate();
  // Get All Categories
  const getAllProducts = async () => {
    // console.log("variables in params:", variables);
    if (variables?.name === "") {
      delete variables.name;
    }
    // return await axios.get(`${Domain}/admin/product`,  {
    return await validate
      .get(`product`, {
        params: variables,
      })
      .then((response) => {
        // console.log("response:", response);
        setProducts(response?.data?.product?.rows);
        setTotal(response?.data?.product?.count);
      });
  };

  const { isLoading, isError, isSuccess, data, refetch } = useQuery(
    "getAllproducts",
    getAllProducts
  );

  // console.log("isError:", isError);
  // console.log("isSuccess:", isSuccess);
  // console.log("isLoading:", isLoading);
  // console.log("data:", data);

  // pagination
  // console.log("total:", total);
  const [products, setProducts] = useState();
  // console.log("products:", products);

  // indices of page
  // const indexOfLastRecord = parsed?.page * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const nPages = total && Math.ceil(total / recordsPerPage);
  const pageNumbers = nPages && [...Array(nPages + 1).keys()]?.slice(1);
  // console.log("page Numbers:", pageNumbers, nPages);

  const handlePrev = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    console.log(pageNumbers?.length);
    console.log(currentPage);
    if (pageNumbers?.length > currentPage + 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    // console.log("state update called");
    // setProducts(data?.data?.product?.rows);
    // console.log("products data updatetd---");
  }, [data]);

  // Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const [deleteId, setDeleteId] = useState();

  const handleDelete = () => {
    mutation.mutate(deleteId);
    handleClose();
  };

  //  Delete Mutations
  const mutation = useMutation(
    (id) => {
      return validate.delete(`${Domain}/admin/product/${id}`);
    }
    // {
    //   onSuccess: () => {
    //     // Invalidate and refetch
    //     queryClient.invalidateQueries("getAllCategories");
    //   },
    // }
  );

  //   const router = useRouter();
  // console.log("mutation:", mutation);
  useEffect(() => {
    if (mutation?.status === "success") {
      toast.success("Successfully deleted");
      setTimeout(() => {
        // router.reload();
        window.location.reload();
      }, 500);
    }
    if (mutation?.status === "error") {
      toast.error("Could not delete category");
    }
  }, [mutation]);

  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  // error code 401
  if (mutation?.error?.response?.status === 401) {
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("access-token");
    toast.success("User logged out!");
    setTimeout(() => {
      navigate("/login");
    }, 100);
  }

  // search via product name

  const handleSearchChange = () => {
    setVariables({ ...variables, name: search });
  };

  // let checkId;
  // useEffect(() => {
  //   console.log("checkid before:", checkId);
  //   clearTimeout(checkId);
  //   checkId = setTimeout(() => {
  //     handleSearchChange();
  //   }, 100);
  //   console.log("checkid after:", checkId);
  // }, [search]);

  const handleEnterButton = (e) => {
    console.log("button:", e.keyCode);
    if (e.keyCode === 13) {
      handleSearchChange();
    }
  };

  const handleClearSearch = () => {
    const dummy = { ...variables };
    delete dummy.name;
    setVariables(dummy);
    setSearch("");
  };
  return (
    <>
      <ToastContainer />
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-5 rounded"
            xl="11"
            style={
              {
                // backgroundColor: "white",
                // // paddingTop: "5%",
                // // paddingLeft: "5%",
                // // paddingRight: "5%",
                // boxShadow: "1px 1px 5px 1px #BFBFBF",
              }
            }
          >
            <h5 className="text-start font-weight-500 mb-4">Products</h5>
            <Row
              className="d-flex justify-content-between rounded-3 mx-0 my-3 py-3 w-100"
              style={{
                backgroundColor: "white",
                boxShadow: "0px 1px 35px #e6e6e6",
              }}
            >
              <Col xxl="4" className="pt-2">
                <div className="search-bar">
                  <input
                    className="search-field"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    // onClick={()=>handleSearchChange()}
                    placeholder="Search..."
                    onKeyDown={handleEnterButton}
                  />
                  {!search && (
                    <AiOutlineSearch className="products-search-bar pointer" />
                  )}

                  {search && (
                    <AiOutlineClose
                      className="products-search-bar pointer"
                      onClick={(e) => handleClearSearch(e)}
                    />
                  )}
                </div>
              </Col>
              {/* <Col xxl="2" className="pt-2">
                <div className="d-flex">
                  <p className="mx-0 mt-2 p-0">By</p>

                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Name"
                    className="search-dropdown mx-2"
                  >
                    <Dropdown.Item href="#/action-1">Name</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Id</Dropdown.Item>
                  </DropdownButton>

                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Category"
                    className="search-dropdown mx-2"
                  >
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  </DropdownButton>
                </div>
              </Col>

              <Col xxl="3" className=" pt-2">
                <DateRangePicker
                  initialSettings={{
                    startDate: "1/1/2014",
                    endDate: "3/1/2014",
                  }}
                >
                  <button className="date-picker">
                    --/--/-- &nbsp; &nbsp; &nbsp; &nbsp; --/--/--
                  </button>
                </DateRangePicker>
            
              </Col> */}

              {/* <Col xxl="1" className=" pt-2">
                <AiOutlineClose fontSize={24} className="mt-2" />
              </Col> */}

              <Col xxl="3" className=" pt-2">
                <Button
                  variant="primary"
                  type="submit"
                  // className="users-addbutton"
                  className="full-button w-100"
                  onClick={() => navigate("/add-product")}
                >
                  + Add Products
                </Button>
              </Col>
            </Row>

            {isLoading && (
              <div className="d-flex">
                <Spinner animation="border" className="my-3 mx-auto" />
              </div>
            )}
            <Table
              className="w-100 bg-light my-4"
              // responsive
              style={{
                // boxShadow: "1px 1px 3px 1px #b1b2b2",
                boxShadow: "0px 1px 35px #e6e6e6",
                borderRadius: "6px",
                // overflowX: "auto",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#e5e5e5" }}>
                  <th className="heading text-start px-3 py-3">Product Name</th>
                  <th className="heading text-start px-3 py-3">Description</th>
                  <th className="heading text-start px-3 py-3">Price (Pkr)</th>
                  <th className="heading text-start px-3 py-3">Status</th>
                  {/* <th className="heading text-start px-3 py-3">Out of Stock</th> */}
                  <th className="heading text-start px-3 py-3">Category Id</th>
                  <th className="heading text-start px-3 py-3">Detail</th>
                  <th className="heading text-start px-3 py-3">Action</th>
                  <th className="heading text-start px-3 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {products?.length === 0 ? (
                  <p>No User present in the Database</p>
                ) : (
                  products &&
                  products?.map((product, index) => {
                    return (
                      <tr key={index}>
                        {/* <td className="text text-start px-3 py-3 family-jost font-size-15 d-flex" style={{maxWidth:"300px"}}>
                          <div
                            className="relative"
                            style={{
                              width: "20%",
                              backgroundColor: "#e5e5e5",
                              borderRadius: "50px",
                            }}
                          >
                            <img
                              className="image p-1"
                              src={product?.productImages[0]?.url}
                              alt="logo"
                              fill
                            />
                          </div>
                          <span className="mx-3 pt-2">{product?.name}</span>
                        </td> */}

                        <td className="text-start px-3 py-3 family-jost font-size-15 font-weight-600">
                          <img
                            src={product?.productImages[0]?.url}
                            className="w-100"
                            style={{ maxWidth: "30px" }}
                            alt="img"
                          />
                          <span className="mx-1"> {product?.name} </span>
                        </td>

                        <td className="text text-start px-3 py-3 family-jost font-size-15">
                          {/* {product?.description.slice(0, 80)} */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: product?.description.slice(0, 80),
                            }}
                          />
                        </td>
                        <td className="text text-start px-3 py-3 family-jost font-size-15">
                          {product?.price && `Rs. ${product?.price}` || "- - -"}
                        </td>
                        <td
                          className={
                            product?.availability === "sale"
                              ? "text text-start px-3 py-3 font-weight-600 family-jost text-success py-3 font-size-15"
                              : product?.availability === "outofstock"
                                ? "text text-start px-3 py-3 font-weight-600 family-jost text-danger py-3 font-size-15"
                                : "text text-start px-3 py-3 font-weight-600 family-jost text-primary py-3 font-size-15"
                          }
                        >
                          {product?.availability === "outofstock" &&
                            "Out Of Stock"}
                          {product?.availability === "sale" && "Sale"}
                          {product?.availability === "instock" && "In Stock"}
                        </td>
                        {/* <td className="text text-start px-3 py-3 font-weight-600 family-jost py-3 font-size-15">
                          {product?.outStock === true
                            ? "✓"
                            : product?.outStock === false
                            ? "X"
                            : "unknown"}{" "}
                        </td> */}
                        <td className="text text-start px-3 py-3 family-jost py-3 font-size-15">
                          {product?.categoryId}
                        </td>
                        <td className="text text-start px-3 py-3">
                          <IoMdEye
                            color="blue"
                            size={16}
                            className=""
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/detail-product/${product?.id}`)
                            }
                          />
                        </td>
                        <td className="text text-start px-3 py-3">
                          <BsPen
                            size={15}
                            color="gray"
                            className=""
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/update-product/${product?.id}`)
                            }
                          />
                        </td>
                        <td className="text text-start px-3 py-3 ">
                          <ImBin
                            size={15}
                            color="red"
                            className=""
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShow(product?.id)}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>

            <Row>
              <Col xxl="5" xl="6" lg="6" md="6" sm="8" className="mx-auto">
                <nav className="my-2 q">
                  <ul
                    className="pagination justify-content-center"
                    style={{ overflowX: "auto" }}
                  >
                    <li className="page-item text-dark">
                      <p
                        onClick={() => handlePrev()}
                        className="text px-3 py-1 rounded mx-3 pagination-button"
                      >
                        Prev
                      </p>
                    </li>
                    {pageNumbers &&
                      pageNumbers?.map((pgNumber) => (
                        <li key={pgNumber}>
                          <p
                            className={`text page-item mx-1 px-3 py-1 ${currentPage == pgNumber - 1 ? "activePage" : ""
                              } `}
                            style={{
                              cursor: "pointer",
                              boxShadow: "1px 1px 2px gray",
                            }}
                            onClick={() => setCurrentPage(pgNumber - 1)}
                          >
                            {pgNumber}
                          </p>
                        </li>
                      ))}

                    <li className="page-item">
                      <p
                        onClick={() => handleNext()}
                        className="text px-3 py-1 rounded mx-3 pagination-button"
                      >
                        Next
                      </p>
                    </li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal className="family-jost" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this category?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Products;
