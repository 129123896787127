import React, { useState, useEffect } from "react";
import "./style.css";
import { Form, FormGroup, Input, Button, Row, Col } from "react-bootstrap";
// import Image from "next/image";
import background from "../assets/dashboard.jpg";
import logo from "../assets/logo.png";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
// import Router from "next/router";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
// import { Domain } from "@/components/config/lib";
import {Domain} from "../config/default";

const Signup = () => {
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    userName: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const handleSignup = (e) => {
    e.preventDefault();
    console.log("credentials:", credentials);
    mutation.mutate(credentials);
  };

  // Mutations
  const mutation = useMutation((data) => {
    return axios.post(`${Domain}/admin/signup`, data);
  });

  console.log("mutation:", mutation);

  useEffect(() => {
    if (mutation?.status === "success") {
      toast.success(mutation?.data?.data?.message);
      // const accessToken = mutation?.data?.data?.admin?.accessToken;
      // const refreshToken = mutation?.data?.data?.admin?.refreshToken;

      // localStorage.setItem("access-token", accessToken);
      // localStorage.setItem("refresh-token", refreshToken);
      // axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      //   Router.push("/login");
      navigate("/login");
    }
    if (mutation?.status === "error") {
      toast.error(mutation?.error?.response?.data?.message);
    }
  }, [mutation?.status]);

  return (
    <>
      <ToastContainer />
      <div>
        {/* {loading && <LinearProgress />} */}
        <Row className="m-0 p-0 vh-100">
          <Col
            xxl="8"
            className="login-image-section"
            style={{
                backgroundImage: `linear-gradient(90deg, #a7c9683d, #ffffffa6, #a7c9683d), url(${background})`,
              objectFit: "fill",
              height: "100vh",
              backgroundSize: "cover",
              // opacity:"0.8"
              // backgroundSize: "100% 100vh, cover",
              // backgroundRepeat:"no-repeat"
            }}
          >
            <div className="relative vh-100 d-flex align-items-center justify-content-center">
              <img
                className="image-logo-login px-3 my-4"
                //   loader={myLoader}
                src={logo}
                alt="logo"
                fill
                // width={320}
                // height={50}
              />
            </div>
          </Col>

          <Col
            xxl="4"
            style={{ backgroundColor: "#eeeeee" }}
            className="d-flex align-items-center"
          >
            <Form className="w-100 form-main" onSubmit={(e) => handleSignup(e)}>
              <h5
                className="text-start font-weight-500 family-jost mb-4 pointer"
                onClick={() => window.history.back()}
              >
                {" "}
                <MdOutlineKeyboardBackspace fontSize={30} />{" "}
                <span className="mx-3"> Back </span>
              </h5>

              <h3 className="family-jost font-size-50 text-center my-4 font-weight-800">
                Registration
              </h3>

              <input
                className="input-field my-4 py-3"
                type="text"
                placeholder="Enter username"
                name="userName"
                value={credentials?.userName}
                onChange={(e) => handleChange(e)}
                required
              />

              <input
                className="input-field my-4 py-3"
                type="email"
                placeholder="Enter email"
                name="email"
                value={credentials?.email}
                onChange={(e) => handleChange(e)}
                required
              />

              <input
                className="input-field my-4 py-3"
                type="password"
                placeholder="Password"
                name="password"
                value={credentials?.password}
                onChange={(e) => handleChange(e)}
                required
              />

              <Button
                // onClick={(e) => e.preventDefault()}
                className="w-100 py-3 my-2 full-button family-jost font-size-18"
                // variant="primary"
                type="submit"
              >
                Register
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Signup;
