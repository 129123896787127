import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
// import Router from "next/router";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { useMutation } from "react-query";
import axios from "axios";
// import { Domain } from "@/components/config/lib";
import { Domain } from "../../config/default";
import { ToastContainer, toast } from "react-toastify";
import Subheader from "../../components/subheader";
import { validate } from "../../components/auth";

const AddSize = () => {
  const sizeFormData = new FormData();
  const navigate = useNavigate();
  const [addSize, setAddSize] = useState({
    size: "",
    length: "",
    width: "",
    height: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddSize({
      ...addSize,
      [name]: value,
    });
  };

  const handleSize = (e) => {
    e.preventDefault();
    console.log(addSize);

    sizeFormData.append("size", addSize?.size);
    sizeFormData.append("length", addSize?.length);
    sizeFormData.append("width", addSize?.width);
    sizeFormData.append("height", addSize?.height);

    mutation.mutate(addSize);
  };

  // Mutations
  const mutation = useMutation((data) => {
    return validate.post(`${Domain}/admin/size`, data);
  });

  console.log("mutation:", mutation);

  // error code 401
  if (mutation?.error?.response?.status === 401) {
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("access-token");
    // toast.success("User logged out!");
    setTimeout(() => {
      navigate("/login");
    }, 100);
  }

  useEffect(() => {
    if (mutation?.status === "success") {
      toast.success(mutation?.error?.response?.data?.message);
      // toast.success(data?.data?.message);
      navigate("/sizes");
    }
    if (mutation?.status === "error") {
      toast.error(mutation?.error?.response?.data?.message);
    }
  }, [mutation]);

  // category icon
  // const handleImage = (e) => {
  //   e.preventDefault();
  //   console.log("e:", e.target);
  //   categoryFormData.append("image", e.target.files[0]);
  //   console.log("image", categoryFormData.get("image"));
  // };

  return (
    <>
      <ToastContainer />
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-5 p-5 rounded"
            xl="11"
            style={{
              backgroundColor: "white",
              boxShadow: "1px 1px 1px 1px #b1b2b2",
            }}
          >
            <h5
              className="text-start font-weight-500 family-jost mb-4 pointer"
              onClick={() => window.history.back()}
            >
              {" "}
              <MdOutlineKeyboardBackspace fontSize={30} />{" "}
              <span className="mx-3"> Sizes </span>
            </h5>

            <Form onSubmit={(e) => handleSize(e)}>
              <Row className="mx-auto">
                <Col xxl="7" className="mx-auto">
                  <Row className="my-3">
                    <Col xxl="9">
                      <p className="font-size-18 font-weight-500 family-jost my-2 text-start">
                        Name
                      </p>
                      <input
                        name="size"
                        onChange={(e) => handleChange(e)}
                        value={addSize?.size}
                        type="text"
                        className="input-field"
                        placeholder="Enter size name"
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col xxl="3">
                      <p className="font-size-18 font-weight-500 family-jost my-2 text-start">
                        Length
                      </p>
                      <input
                        name="length"
                        onChange={(e) => handleChange(e)}
                        value={addSize?.length}
                        type="text"
                        className="input-field"
                        placeholder="Enter length"
                        required
                      />
                    </Col>
                    <Col xxl="3">
                      <p className="font-size-18 font-weight-500 family-jost my-2 text-start">
                        Width
                      </p>
                      <input
                        name="width"
                        onChange={(e) => handleChange(e)}
                        value={addSize?.width}
                        type="text"
                        className="input-field"
                        placeholder="Enter width"
                        required
                      />
                    </Col>
                    <Col xxl="3">
                      <p className="font-size-18 font-weight-500 family-jost my-2 text-start">
                        Height
                      </p>
                      <input
                        name="height"
                        onChange={(e) => handleChange(e)}
                        value={addSize?.height}
                        type="text"
                        className="input-field"
                        placeholder="Enter height"
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="my-5">
                    <Col xxl="6" className="">
                      <Button
                        className="w-100 full-button"
                        variant="success"
                        type="submit"
                        // onClick={() => mutation.mutate(addCategory)}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddSize;
