import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { QueryClient } from "react-query";
import { QueryClientProvider } from "react-query";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
// react-alick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// react-responsive-carousel
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"; // react-draft-wysiwyg
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'; // draft to html / styled

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // refetchOnmount: true,
      // refetchOnReconnect: false,
      // retry: false,
    },
  },
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // Provide the client to your App
  <QueryClientProvider client={queryClient}>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
