import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Subheader from "../components/subheader";
import { AiOutlineNotification } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const navigate = useNavigate();
  return (
    <>
      <ToastContainer />
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-4 p-5 rounded"
            xl="11"
            style={{
              backgroundColor: "white",
              boxShadow: "1px 1px 1px 1px #b1b2b2",
            }}
          >
            <h5
              className="text-start font-weight-500 family-jost mb-2 pointer"
              // onClick={() => window.history.back()}
            >
              {" "}
              <AiOutlineNotification fontSize={30} />{" "}
              <span className="mx-3"> Send Notification </span>
            </h5>

            <Form
            //  onSubmit={(e) => handleProduct(e)}
            >
              <Row className="mx-auto">
                <Col xxl="7" className="mx-auto">
                  <Row className="my-2">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Title
                      </p>
                      <input
                        className="input-field"
                        placeholder="Enter title here"
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col xxl="12">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Message
                      </p>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Enter notification text here"
                          rows={4}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="">
                    <Col xxl="6">
                      <p className="text-start font-size-18 font-weight-500 family-jost my-2">
                        Image
                      </p>
                      <input
                        className="input-field"
                        type="file"
                        placeholder="Enter product price"
                      />
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col xxl="6" className="">
                      {
                        /* <Button className="w-100" variant="success" type="submit">
                      Submit
                    </Button> */
                        <Button
                          variant="primary"
                          type="submit"
                          // className="users-addbutton"
                          className="full-button w-100"
                          onClick={() => navigate("/add-product")}
                        >
                          Add Notification
                        </Button>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Notification;
