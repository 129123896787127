import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
// import Router from "next/router";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { useMutation } from "react-query";
import axios from "axios";
// import { Domain } from "@/components/config/lib";
import { Domain } from "../../config/default";
import { ToastContainer, toast } from "react-toastify";
import Subheader from "../../components/subheader";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { validate } from "../../components/auth";
import { useQuery } from "react-query";
import { AiOutlineCloudUpload } from "react-icons/ai";

const AddCategory = () => {
  // editor state
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  console.log("editor state:", editorState);

  useEffect(() => {
    const currentContent = editorState.getCurrentContent();
    const newContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(newContent);
    console.log("raw content:", rawContent);
    // const stringifyDescription = JSON.stringify(rawContent)
    const draftedDescription = draftToHtml(rawContent);
    console.log("draftedDescription:", draftedDescription);
    setAddCategory({ ...addCategory, description: draftedDescription });
  }, [editorState]);

  const onEditorChange = (data) => {
    // console.log("description:", data)
    // console.log()
    setEditorState(data);
  };

  const categoryFormData = new FormData();
  const navigate = useNavigate();
  const [addCategory, setAddCategory] = useState({
    name: "",
    description: "",
    mainCategoryId: "",
    image: "",
  });
  console.log("addCategory:", addCategory);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddCategory({
      ...addCategory,
      [name]: value,
    });
  };

  const handleCategory = (e) => {
    e.preventDefault();
    console.log(addCategory);

    categoryFormData.append("name", addCategory?.name);
    categoryFormData.append("description", addCategory?.description);
    addCategory?.mainCategoryId &&
      categoryFormData.append("mainCategoryId", addCategory?.mainCategoryId);
    for (let i = 0; i < addCategory.image.length; i++) {
      categoryFormData.append("image", addCategory.image[i]);
    }
    console.log("images in mutation:", categoryFormData.getAll("image"));
    mutation.mutate(categoryFormData);
  };

  // Mutations
  const mutation = useMutation((data) => {
    return validate.post(`${Domain}/admin/category`, data);
  });

  console.log("mutation:", mutation);

  // error code 401
  if (mutation?.error?.response?.status === 401) {
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("access-token");
    // toast.success("User logged out!");
    setTimeout(() => {
      navigate("/login");
    }, 100);
  }

  useEffect(() => {
    if (mutation?.status === "success") {
      toast.success("Success");
      navigate("/categories");
    }
    if (mutation?.status === "error") {
      toast.error("Could not add category");
    }
  }, [mutation]);

  // category icon
  // const handleImage = (e) => {
  //   e.preventDefault();
  //   console.log("e:", e.target);
  //   categoryFormData.append("image", e.target.files[0]);
  //   console.log("image", categoryFormData.get("image"));
  // };

  // handle image in form data
  function handleImage(e) {
    console.log("file type:", e.target.type);
    if (addCategory.image.length > 0) {
      setAddCategory({
        ...addCategory,
        image: [...addCategory.image, e.target.files],
      });
    } else {
      setAddCategory({
        ...addCategory,
        image: e.target.files,
      });
    }
  }

  // Get All Categories
  const getAllCategories = async () => {
    return await validate.get(`${Domain}/admin/category`);
  };

  const {
    isLoading: isLoadingCategory,
    isError: isErrorCategory,
    isSuccess: isSuccessCategory,
    data: dataCategory,
  } = useQuery("getAllCategories", getAllCategories);

  // console.log("isErrorSize:", isErrorSize);
  // console.log("isSuccessSize:", isSuccessSize);
  // console.log("isLoadingSize:", isLoadingSize);
  // console.log("dataSize:", dataSize);

  const [categories, setCategories] = useState();
  console.log("categories:", categories);

  useEffect(() => {
    setCategories(dataCategory?.data?.category?.rows);
    // setMultiselect([...multiselect, {size: "custom value"}])
  }, [dataCategory]);

  return (
    <>
      <ToastContainer />
      <Container fluid style={{ backgroundColor: "#F2F3F4" }}>
        <Subheader />
        <Row>
          <Col
            className="mx-auto mt-5 p-5 rounded"
            xl="11"
            style={{
              backgroundColor: "white",
              boxShadow: "1px 1px 1px 1px #b1b2b2",
            }}
          >
            <h5
              className="text-start font-weight-500 family-jost mb-4 pointer"
              onClick={() => window.history.back()}
            >
              {" "}
              <MdOutlineKeyboardBackspace fontSize={30} />{" "}
              <span className="mx-3"> Categories </span>
            </h5>

            <Form onSubmit={(e) => handleCategory(e)}>
              <Row className="mx-auto">
                <Col xxl="7" className="mx-auto">
                  <Row className="my-3">
                    <Col xxl="6">
                      <p className="font-size-18 font-weight-500 family-jost my-2 text-start">
                        Category Name
                      </p>
                      <input
                        name="name"
                        onChange={(e) => handleChange(e)}
                        value={addCategory?.name}
                        type="text"
                        className="input-field"
                        placeholder="Enter category name"
                        required
                      />
                    </Col>
                    <Col xxl="6">
                      <p className="font-size-18 font-weight-500 family-jost my-2 text-start">
                        Main Category
                      </p>
                      {/* <input
                        name="mainCategoryId"
                        onChange={(e) => handleChange(e)}
                        value={addCategory?.mainCategoryId}
                        type="text"
                        className="input-field"
                        placeholder="Enter Main category id to make is sub category"
                      /> */}
                      <select
                        // className="input-field"
                        className="dropdown-field"
                        name="mainCategoryId"
                        // typeof="dropdown"
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option value="none"> -- select category --</option>
                        {categories &&
                          categories?.map((category, index) => {
                            return (
                              <option value={category?.id} key={index}>
                                {category?.name}
                              </option>
                            );
                          })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col xxl="12">
                      <p className="font-size-18 font-weight-500 family-jost my-2 text-start">
                        Description
                      </p>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorChange}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        required
                        // wrapperClassName="input-field"
                        // editorClassName="editorClassName"
                        // onEditorStateChange={this.onEditorStateChange}
                      />
                      {/* <Form.Group
                        // className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          name="description"
                          onChange={(e) => handleChange(e)}
                          value={addCategory?.description}
                          type="text"
                          placeholder="Enter description of the category"
                          rows={4}
                          required
                        />
                      </Form.Group> */}
                    </Col>
                  </Row>

                  <p className="font-size-18 font-weight-500 family-jost mt-3 text-start">
                        Category Icon
                      </p>
                  <Row className="mb-3">
                    <Col lg="2">
                   
                      <label className="custom-file-upload">
                        <input
                          type="file"
                          onChange={(e) => handleImage(e)}
                          required
                        />
                        <AiOutlineCloudUpload fontSize={30} color="#CF2D39" />
                        <p
                          style={{
                            color: "#CF2D39",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          upload{" "}
                        </p>
                      </label>
                    </Col>
                    <Col xxl="2">
                      {addCategory?.image && (
                        <img
                          src={URL.createObjectURL(addCategory?.image[0])}
                          style={{
                            width: "100%",
                            height: "100px",
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </Col>
                    {/* <Col xxl="6">
                      <p className="font-size-18 font-weight-500 family-jost my-2 text-start">
                        Category Icon
                      </p>
                      <input
                        name="category-icon"
                        onChange={(e) => handleImage(e)}
                        // value={addCategory?.name}
                        type="file"
                        className="input-field"
                        // placeholder="Enter category name"
                        required
                      />
                    </Col> */}
                  </Row>

                  <Row className="my-4">
                    <Col xxl="6" className="">
                      <Button
                        className="w-100 full-button"
                        variant="success"
                        type="submit"
                        // onClick={() => mutation.mutate(addCategory)}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddCategory;
